import { Log } from 'types/dashboard/dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Log = {
    type:'',
    jobId:'',
    time:'',
    date:'',
};

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        setLog: (state: Log, action: PayloadAction<Log>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

const { actions, reducer } = logSlice;

export const { setLog } = actions;

export default reducer;

// Selector
export const logSelector = (state: { logs: Log }) => state.logs;

