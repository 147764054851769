import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IAppProps } from "./types/common/common";
import { useSelector } from "react-redux";
import Layout from "./layout/Layout";
import { tokensSelector } from "./redux/ducks/token";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import EmailSent from "./pages/auth/EmailSent";
import ResetPassword from "./pages/auth/ResetPassword";
import ToastNotification from "./components/toastNotification/ToastNotification";

const UnauthorizedComponent = ({
  children,
  props,
}: {
  children: JSX.Element;
  props: IAppProps;
}) => {
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    if (typeof window === "object") {
      setReadyToRender(true);
    } else {
      setReadyToRender(false);
    }
  }, []);

  return !props.tokens.accessToken ? (
    children
  ) : readyToRender ? (
    <Navigate to={"/dashboard"} />
  ) : null;
};

const App = () => {
  const tokens = useSelector(tokensSelector);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    if (readyToRender === false) {
      setReadyToRender(true);
    }
  }, [readyToRender]);

  return readyToRender ? (
    <div>
      <ToastNotification />
      <Routes>
        <Route
          path="/login"
          element={
            <UnauthorizedComponent props={{ tokens }}>
              <Login />
            </UnauthorizedComponent>
          }
        />
        <Route
          // path="/forgetPassword/:slug"
          path="/forgetPassword"
          element={
            <UnauthorizedComponent props={{ tokens }}>
              <ForgetPassword />
            </UnauthorizedComponent>
          }
        />
        <Route
          path="/resetPassword/:id/:slug"
          // path="/resetPassword"
          element={
            <UnauthorizedComponent props={{ tokens }}>
              <ResetPassword />
            </UnauthorizedComponent>
          }
        />
        <Route path="/sentEmail" element={<EmailSent />} />
        <Route path="*" element={<Layout />} />
      </Routes>
    </div>
  ) : null;
};

export default App;
