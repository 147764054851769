/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validateField = {
  name: Yup.string()
    .trim()
    .required("Name is Required")
    .min(4, "Name Must be at least 3+ characters long"),
  email: Yup.string()
    .trim()
    .required("Email Field is Required")
    .matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/, {
      message: "Please enter a valid email",
    })
    .lowercase()
    .max(150),
  password: Yup.string()
    .trim()
    .required("Password is Required")
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must be at most 25 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!#%*?&]{8,25}$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  stringPrefixJoiValidation: Yup.string().trim(),
  forgetEml: Yup.string()
    .trim()
    .required("Enter your email address to receive a password reset link")
    .matches(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/, {
      message: "Please enter a valid email",
    })
    .max(150),
};

// For Default Image - static path
export const DEFAULT_IMAGE = {
  defaultAppLogo: "/assets/images/logo.svg",
  loginBanner: "/assets/images/Login-Banner.png",
  defaultAvatar: "/assets/images/default.png",
  defaultCsv: "/assets/images/csv-user.png",
  defaultCardImage: "/assets/images/Max-Builders-Default.png",
  defaultDelete: "/assets/images/bin.gif",
  defaultPDF: "/assets/images/PDF-Icon.svg",
  defaultXLS: "/assets/images/XLS-Icon.svg",
  defaultIMG: "/assets/images/IMG-Icon.svg",
};

export const ALLOWED_IMAGE_MIME_TYPES = [
  "image/pjpeg",
  "image/jpeg",
  "image/png",
  "image/x-png",
  "image/apng",
  "image/gif",
  "image/avif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "image/heic",
  "image/heif",
];

export const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes
export const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
export const DEFAULT_COUNTRY_CODE = "61";

export const RESPONSE_STATUS = {
  success: "success" as "success",
  error: "error" as "error",
};

export enum ErrorMessage {
  UserAssignRole = "Error in assigning User Role",
  SomethingWentWrong = "Something Went Wrong",
  RegistrationError = "Error in registration",
  AccessTokenError = "No Access Token Found ",
  UnAuthorizedUser = "Unauthorized User for this portal",
  PhoneRequire = "Phone Number Require",
  EmailVerification = "Please Verify Your Email",
  PhoneVerification = "Please Verify Your Phone",
  PhoneVerificationLink = "Error in sending phone otp",
  EmailVerificationLink = "Error in sending email otp",
  FetchAllReportError = "Error in fetching all reports",
  InvalidToken = "Invalid token",
  TokenExpired = "Token is Expired. Please Login Again!!",
  EmailExist = "Email already exist",
  TemplateCreate = "Error in Creating Template!",
}

// used for user type in add/edit user's type
export enum USER_TYPE {
  worker = "Worker",
  companyAdmin = "Admin",
  staff = "staff",
}

export const USER_TYPE_OPTION = [
  {
    value: "staff",
    label: "Staff",
  },
  {
    value: "worker",
    label: "Worker",
  },
  {
    value: "Admin",
    label: "Admin",
  },
];

export const NESTED_ROUTE = ["administration", "dashboard", "transport"];

export const DEFAULT_MAP_LONGITUDE = -89.61231607409995;
export const DEFAULT_MAP_LATITUDE = 20.968015340708085;

export const JOB_INFO_TABS = {
  details: {
    key: "details",
    title: "Builder Job Details",
    modalTitle: "ABCD",
  },
  erect: {
    key: "erect",
    title: "Erect",
    modalTitle: "ERECT",
  },
  attachments: {
    key: "attachments",
    title: "Attachments",
    modalTitle: "Document",
  },
  plans: {
    key: "plans",
    title: "plans",
    modalTitle: "Plans",
  },
  scheduledWorks: {
    key: "scheduledWorks",
    title: "Scheduled Works",
    modalTitle: "scheduledWorks",
  },
  notes: {
    key: "notes",
    title: "Notes",
    modalTitle: "Notes",
  },
  activityLogs: {
    key: "activityLogs",
    title: "Activity Logs",
    modalTitle: "activityLogs",
  },
  reports: {
    key: "reports",
    title: "Reports",
    modalTitle: "reports",
  },
  timesheet: {
    key: "timesheet",
    title: "Timesheet",
    modalTitle: "timesheet",
  },
};
export enum SUPPORTED_ATTACHMENT_FORMAT {
  PDF = "application/pdf",
  JPG = "image/jpg",
  PNG = "image/png",
  JPEG = "image/jpeg",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  heic = "image/heic",
  heif = "image/heif",
  CSV = "text/csv",
}

export const SUPPORTED_ATTACHMENT_FORMAT_ARRAY: SUPPORTED_ATTACHMENT_FORMAT[] =
  [
    SUPPORTED_ATTACHMENT_FORMAT.PDF,
    SUPPORTED_ATTACHMENT_FORMAT.JPG,
    SUPPORTED_ATTACHMENT_FORMAT.JPEG,
    SUPPORTED_ATTACHMENT_FORMAT.PNG,
    SUPPORTED_ATTACHMENT_FORMAT.XLS,
    SUPPORTED_ATTACHMENT_FORMAT.XLSX,
  ];

export const SUPPORTED_PLAN_FORMAT_ARRAY: SUPPORTED_ATTACHMENT_FORMAT[] = [
  SUPPORTED_ATTACHMENT_FORMAT.PDF,
];

export const DEFAULT_SUPPORTED_IMAGE_FORMAT = [
  SUPPORTED_ATTACHMENT_FORMAT.JPG,
  SUPPORTED_ATTACHMENT_FORMAT.JPEG,
  SUPPORTED_ATTACHMENT_FORMAT.PNG,
  SUPPORTED_ATTACHMENT_FORMAT.heif,
  SUPPORTED_ATTACHMENT_FORMAT.heic,
];

export const SUPPORTED_CSV_FORMAT_ARRAY = [SUPPORTED_ATTACHMENT_FORMAT.CSV];

export const durationoptions = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 Days", value: "l30d" },
  { label: "This Month", value: "thisMonth" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last 90 Days", value: "l90d" },
  { label: "Custom Range", value: "customRange" },
];

export const timeSheetOptions = [
  { label: "Staff", value: "staff" },
  { label: "Workers", value: "worker" },
];

export enum KEY_OF_TYPE_OF_RESPONSE {
  DOCUMENT_NUMBER = "document_number",
  TEXT_ANSWER = "text_answer",
  NUMBER = "number",
  CHECKLIST = "checklist",
  DATE_N_TIME = "date_time",
  MEDIA = "media",
  // SIGNATURE = "signature",
  // LOCATION = "location",
  INSTRUCTION = "instruction",
  MULTICHOICE = "multichoice",
}

export enum KEY_OF_MULTIPLE_CHOICE_OPTION {
  GOOD_FAIR = "good-fair",
  SAFE_RISK = "safe-risk",
  PASS_FAIL = "pass-fail",
  YES_NO = "yes-no",
  DEFAULT = "default",
}
export const MULTIPLE_CHOICE_OPTION_FORM_MAKER = {
  [KEY_OF_MULTIPLE_CHOICE_OPTION.GOOD_FAIR]: [
    {
      value: "good",
      color: "success",
      label: "Good",
    },
    {
      value: "fair",
      color: "warning",
      label: "Fair",
    },
    {
      value: "poor",
      color: "danger",
      label: "Poor",
    },
    {
      value: "default",
      color: "default",
      label: "N/A",
    },
  ],
  [KEY_OF_MULTIPLE_CHOICE_OPTION.PASS_FAIL]: [
    {
      value: "pass",
      color: "success",
      label: "Pass",
    },
    {
      value: "fail",
      color: "danger",
      label: "Fail",
    },
    {
      value: "default",
      color: "default",
      label: "N/A",
    },
  ],
  [KEY_OF_MULTIPLE_CHOICE_OPTION.YES_NO]: [
    {
      value: "yes",
      color: "success",
      label: "Yes",
    },
    {
      value: "no",
      color: "danger",
      label: "No",
    },
    {
      value: "default",
      color: "default",
      label: "N/A",
    },
  ],
};

export const TAMPLATE_TABS = {
  form: {
    key: "build",
    title: "Build",
  },
  preview: {
    key: "preview",
    title: "Preview",
  },
};

export const roleType = {
  admin: "admin",
  staff: "staff",
  worker: "worker",
  company: "company",
  companyAdmin: "companyAdmin",
};

export enum ReportStatusEnum {
  Inprogress = "in-progress",
  Completed = "completed",
}

export const TRANSPORT_PICKUP = [
  {
    value: "full_dismantle",
    label: "Full Dismantle",
  },
  {
    value: "excess",
    label: "Excess",
  },
  {
    value: "Part_dismantle",
    label: "Part Dismantle",
  },
];

export const TRANSPORT_PALLETS = [
  {
    value: "wrapped",
    label: "Wrapped",
  },
  {
    value: "notwrapped",
    label: "Not Wrapped",
  },
];

export const TRANSPORT_REASON = [
  { label: "Change of Drawings", value: "Change of Drawings" },
  { label: "Gear Not Deliver", value: "Gear Not Deliver" },
  { label: "Incorrect Gear Sent", value: "Incorrect Gear Sent" },
  { label: "Other", value: "other" },
];
export enum REPORT_OVERVIEW {
  createdBy = "Prepared by",
  location = "Location",
}

export const TRANSPORT_HIRE_TYPE = [
  {
    value: "pickup",
    label: "pick-up",
    checked: true,
  },
  {
    value: "return",
    label: "Return",
    checked: false,
  },
];

export const UPLOAD_IMAGE_SIZE = 10 * 1e6; // 10MB
export const TABLET_VIEW = 1279;

export const REPEAT_OPTIONS = [
  {
    value: "does_repeat",
    label: "Does Repeat",
  },
  {
    value: "no_repeat",
    label: "No Repeat",
  },
];

export const MONTHLY_OPTIONS = [
  {
    value: "monthlyOnTheFirstMonday",
    label: "Monthly on the first Monday",
  },
];

export const CALENDER_OPTIONS = [
  {
    value: "day",
    label: "Day",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "year",
    label: "Year",
  },
];

export const WEEK_OPTIONS = [
  {
    value: "sunday",
    label: "Sunday",
    checked: false,
  },
  {
    value: "monday",
    label: "Monday",
    checked: false,
  },
  {
    value: "tuesday",
    label: "Tuesday",
    checked: false,
  },
  {
    value: "wednesday",
    label: "Wednesday",
    checked: false,
  },
  {
    value: "thursday",
    label: "Thursday",
    checked: false,
  },
  {
    value: "friday",
    label: "Friday",
    checked: false,
  },
  {
    value: "saturday",
    label: "Saturday",
    checked: false,
  },
];

export const FILTER_FIRST_OPTIONS = [
  {
    value: "type",
    label: "Type",
  },
  {
    value: "role",
    label: "Role",
  },
];

export const defaultDate = new Date();
defaultDate.setHours(0);
defaultDate.setMinutes(0);
defaultDate.setSeconds(0);
defaultDate.setMilliseconds(0);

export const SCHEDULE_CATEGORY = [
  { label: "Delivery", value: "delivery" },
  { label: "Pickup", value: "pickUp" },
  { label: "Order More Gear", value: "orderMoreGear" },
  { label: "Other", value: "other" },
];

export const EVENT_OPTION = [
  { label: "Job", value: "job" },
  { label: "Transport", value: "transport" },
];
