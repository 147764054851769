import React from "react";

const SiteTrouble = () => {
  return (
    <section className="h-[calc(100dvh_-_40px)] flex items-center justify-center">
      <div className="notfound text-center">
        <div className="notfound-404 relative mb-6">
          <h1 className="sm:text-[160px] text-[200px] leading-none font-BinerkaDemo font-bold text-primaryGold">
            <span className="drop-shadow-[-8px_0_0_#000] text-themeColor">
              O
            </span>
            <span className="drop-shadow-[-8px_0_0_#000] text-themeColor">
              o
            </span>
            <span className="drop-shadow-[-8px_0_0_#000] text-themeColor">
              p
            </span>
            <span className="drop-shadow-[-8px_0_0_#000] text-themeColor">
              s
            </span>
            <span className="drop-shadow-[-8px_0_0_#000] text-themeColor">
              !
            </span>
          </h1>
        </div>
        <h2 className="text-lg mb-8 font-BinerkaDemo max-w-[300px] mx-auto font-bold">
          Something went wrong. Try later.
        </h2>
      </div>
    </section>
  );
};

export default SiteTrouble;
