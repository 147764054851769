import React from "react";

interface ImageProps {
  src?: string | null;
  alt?: string;
  defaultImage: string;
  imageClassName?: string;
  height?: number;
  width?: number;
  onLoad?:()=> void;
}

const Image = (props: ImageProps) => {
  const { alt, defaultImage, src, imageClassName , width , height , onLoad } = props;
  return (
    <img
      src={(src as string) || ""}
      alt={alt || "Image"}
      height={height || 40}
      width={width || 40}
      className={`object-cover object-center w-full h-full ${imageClassName}`}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = defaultImage;
        e.currentTarget.srcset = "";
      }}
      onLoad={onLoad}
    />
  );
};

export default Image;
