import { axiosPost } from "axios/config";

export const login = (data: object) => {
  return axiosPost(`/auth/login`, data);
};

export const forgetPassword = (data: object) => {
  return axiosPost(`/auth/forgot-password`, data);
};

export const resetPassword = (data: object) => {
  return axiosPost(`/auth/password-reset`, data);
};

export const logoutUser = () => {
  return axiosPost(`/auth/logout`,{})
}