import { useSelector } from "react-redux";
import { tokensSelector } from "redux/ducks/token";
import { Navigate } from "react-router-dom";
import { getUserRole} from "utils/navigation.utils";
import { profileSelector } from "redux/ducks/profile";
import { checkPermission } from "helper/common/function";

const AuthGuard = ({
  props,
  children,
  path,
  modulename,
  userTabs,
}: {
  path?: string;
  props: any;
  children: JSX.Element;
  modulename: string[];
  userTabs: string[] | undefined;
}) => {
  const tokens = useSelector(tokensSelector);
  const profileData = useSelector(profileSelector);
  const [data]: any = getUserRole(profileData.rolesDetails.type, profileData.permissions);

  const byPass =
    process.env.REACT_APP_PERMISSION_BYPASS === "false" ? false : true;

  if (tokens.accessToken) {
    if (userTabs?.includes(modulename[0]) && byPass) {
      if (path) {
        const access = checkPermission(data, path, modulename[0])
        if (typeof access === 'object' && access.length === 0) {
          return <Navigate to={"/404"} replace={true} />;
        }
      }

      return children;
    } else {
      return <Navigate to={"/404"} replace={true} />;
    }
  } else {
    return <Navigate to={"/auth/login"} replace={true} />;
  }
};

export default AuthGuard;
