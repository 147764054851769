import React, { useState } from "react";
import cx from "classnames";
import { UseFormRegisterReturn } from "react-hook-form";
import { PiWarningCircle } from "react-icons/pi";
import { FaRegEyeSlash } from "react-icons/fa6";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const PasswordInput = ({
  className,
  parentClassName,
  placeholder,
  label = false,
  error = false,
  required = false,
  register,
  onChange,
}: {
  register?: UseFormRegisterReturn;
  className?: any;
  parentClassName?: any;
  placeholder?: any;
  label?: any;
  error?: any;
  endIcon?: any;
  icon?: any;
  required?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const classes = cx(
    className,
    `relative bg-white w-full py-1.5 px-3 border border-solid border-gray-400 rounded-lg placeholder:text-gray-600 !text-inputtextcolor text-base font-normal leading-150% focus:outline-none ${
      !error ? "focus:border-gray-600" : "!border-Darkred pr-14"
    }`
  );

  const [pwd, setPwd] = useState(false);

  return (
    <div className={`relative ${parentClassName} `}>
      {label && (
        <label className="text-contentcolor text-base leading-150% font-normal mb-1.5 block">
          {label}{" "}
          {required && <span className="text-Darkred font-medium">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          type={pwd ? "text" : "password"}
          placeholder={placeholder}
          className={classes}
          {...register}
        />
        {error && (
          <button className="absolute right-3 z-[1] text-Darkred top-2/4 -translate-y-2/4">
            <PiWarningCircle />
          </button>
        )}
        <button
          type="button"
          onClick={() => setPwd(!pwd)}
          className={`absolute text-secondary text-xl z-[1] top-2/4 -translate-y-2/4 ${
            error ? "right-8" : "right-3"
          } `}
        >
          <span>{pwd ? <MdOutlineRemoveRedEye /> : <FaRegEyeSlash />}</span>
        </button>
      </div>
      {error && (
        <p className="text-Darkred text-sm font-normal leading-150% mt-1">
          {error}
        </p>
      )}
    </div>
  );
};

export default PasswordInput;
