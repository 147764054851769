import { logoutUser } from "services/auth";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { LogoutIcon } from "icons";
import { removeTokens } from "redux/ducks/token";

interface ILogout {
  isLogoutOpen: boolean;
  setIsLogoutOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Logout = (props: ILogout) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isLogoutOpen, setIsLogoutOpen } = props;
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await logoutUser();
      dispatch(removeTokens());
      setIsLogoutOpen(false);
      setIsLoading(false);
    } catch (error) {
      dispatch(removeTokens());
      setIsLogoutOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isLogoutOpen}
        onClose={() => setIsLogoutOpen(false)}
        onConfirm={() => {
          handleLogout();
        }}
        icon={<LogoutIcon />}
        title={"Are you sure want to logout?"}
        message={""}
        isLoading={isLoading}
      />
    </>
  );
};

export default Logout;
