import { axiosDelete, axiosGet, axiosPost } from "axios/config";

export const getDashboardCounts = () => {
  return axiosGet(`/dashboard/counts`);
};

export const getDashboardRecentJobs = () => {
  return axiosGet(`/dashboard/recent-jobs`);
};

export const getNotification = (param?: object) => {
  return axiosGet(`/notification/list`, param ?? {});
};

export const clearAllNotificationsApi = () => {
  return axiosDelete(`/notification/clear`);
};
export const clearNotificationsById = (id: string) => {
  return axiosDelete(`/notification/delete/${id}`);
};

export const readNotification = (ids: object) => {
  return axiosPost(`/notification/read`, ids);
};

export const updatePermissionDuck = (id: string) => {
  return axiosGet(`/auth/user-profile/${id}`);
};

export const permissionOutlook = (ids: object) => {
  return axiosPost(`/connect-outlook`, ids);
};

export const getJobList = (userId?: string) => {
  return axiosGet(`/dashboard/jobs-list`, { userId });
};

export const getPreCheckInData = () => {
  return axiosGet(`/dashboard/last-job-timesheet`);
};
