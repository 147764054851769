import { notification } from 'types/dashboard/dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState:notification = {
    count: 0,
    notificationList:[]
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationCount: (state: notification, action: PayloadAction<Omit<notification,"notificationList">>) => {
            return {
                ...state,
                count:action.payload.count
            };
        },
        setNotificationList: (state: notification, action: PayloadAction<Omit<notification,"count">>) => {
            return {
                ...state,
                notificationList : action.payload.notificationList,
            };
        },
    },
});

const { actions, reducer } = notificationSlice;

export const { setNotificationCount,setNotificationList } = actions;

export default reducer;

// Selector
export const notificationSelector = (state  : { notification : notification }) => state.notification.count;
export const notificationListSelector = (state  : { notification : notification }) => state.notification.notificationList;
