import React, { useEffect, useState } from "react";
import cx from "classnames";
import { UseFormRegisterReturn } from "react-hook-form";
import ReactSelect, { StylesConfig, components } from "react-select";

import CreatableSelect from "react-select/creatable";

type valueType = {
  value: string | boolean | number;
  label?: string;
};

const Select = React.forwardRef(
  (
    {
      label = "",
      placeholder,
      parentClassName = "",
      labelClassName = "",
      error = "",
      required = false,
      options,
      className,
      register,
      name,
      info,
      size = "md",
      border = "md",
      onChange,
      value,
      defaultValue,
      isMulti = false,
      isCreateble = false,
      disabled = false,
      isClearable = false,
      isSearchable = false,
      optionClassName = "",
      onCreateOption,
      getOptionLabel,
      dynamicHeight
    }: {
      getOptionLabel?:any;
      label?: string;
      placeholder?: string;
      parentClassName?: string;
      labelClassName?: string;
      error?: any;
      required?: boolean;
      options?: any;
      className?: string;
      filled?: string;
      showIndicator?: boolean;
      darkPlaceholder?: boolean;
      info?: boolean;
      size?: "sm" | "md" | "lg";
      border?: "sm" | "md" | "none";
      register?: UseFormRegisterReturn;
      name?: string;
      onChange?: (...event: any[]) => void;
      value?: valueType | valueType[];
      defaultValue?: valueType | valueType[] | null;
      isMulti?: boolean;
      isCreateble?: boolean;
      disabled?: boolean;
      isClearable?: boolean;
      isSearchable?: boolean;
      optionClassName?: string;
      onCreateOption?:any;
      dynamicHeight?: string,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState("");
    // const sSize = cx(
    //   { [`36px`]: size === "sm" },
    //   { [`40px`]: size === "md" },
    //   { [`44px`]: size === "lg" }
    // );
    const sBorder = cx(
      { [`0px`]: border === "none" },
      { [`1px`]: border === "sm" },
      { [`2px`]: border === "md" }
    );

    const customStyles: StylesConfig = {
      control: (base: any, state: any) => ({
        ...base,
        minHeight: "46px",
        fontSize: "16px",
        // height: dynamicHeight ?? "46px",
        padding: "4px 15px",
        borderRadius: "8px",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: disabled ? "rgb(229, 229, 229, 0.3)" : "#ffffff",
        borderWidth: sBorder,
        borderColor: error
          ? "#B01115"
          : state.isFocused
          ? "#CED4DA"
          : "#CED4DA",
        "&:hover": {
          borderColor: error ? "#B01115" : "",
        },
        outline: "none !important",
        boxShadow: "none !important",
      }),
      placeholder: (base: any) => {
        return {
          ...base,
          color: "#6C757D",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "initial",
          fontSize: "16px",
        };
      },
      valueContainer: (base: any) => ({
        ...base,
        padding: "0",
        marginRight: "8px",
      }),
      indicatorSeparator: (base: any) => ({
        ...base,
        display: "none",
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        padding: "0",
        color: "#211f214d",
      }),
      menu: (base: any) => ({
        ...base,
        marginBottom: "-4px",
        padding: "10px",
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        border: "1px solid rgba(52, 45, 59, 0.3)",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.07)",
        zIndex: 99,
      }),
      option: (base: any, state: any) => ({
        ...base,
        padding: "10px 16px",
        fontSize: "14px",
        marginBottom: "4px",
        borderRadius: "4px",
        cursor: "pointer",
        color: state.isSelected ? "#211F21" : "#211F21",
      }),
      noOptionsMessage: (base: any) => ({
        ...base,
        borderRadius: "0",
        color: "#092540",
        fontSize: "16px",
      }),
      multiValue: (base: any) => ({
        ...base,
        background: "#0c0c0c",
        color: "#ffffff",
        fontWeight: 500,
        padding: "6px 8px",
        borderRadius: "0.25rem",
      }),
      multiValueLabel: (base: any) => ({
        ...base,
        color: "#ffffff",
        padding: "0 !important",
        fontSize: 10,
      }),
      multiValueRemove: (base: any) => ({
        ...base,
        padding: "0 !important",
        marginLeft: "5px",
        background: "#0c0c0c !important",
      }),
    };

    useEffect(() => {
      if (value && !Array.isArray(value) && value.value) {
        const tempValue = options.filter(
          (op: { value: string }) => op.value === value.value
        );
        setSelectedValue(tempValue[0]);
      } else {
        setSelectedValue("");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const Option = ({ data, isSelected, ...props }: any) => (
      <components.Option
        {...props}
        className={`${isSelected && "!bg-active hover:!bg-active"} hover:!bg-inactive`}
      >
        <div className={`flex items-center ${optionClassName} `}>
          {data.icon && <span className="block mr-2">{data.icon}</span>}
          <span className="block leading-normal">{data.label}</span>
        </div>
      </components.Option>
    );

    return (
      <div className={`relative flex flex-col ${parentClassName} `}>
        {label && (
          <label
            className={`text-contentcolor text-base leading-150% font-normal mb-1.5 block ${labelClassName} `}
          >
            {label}
            {required && <span className="text-Darkred font-medium">*</span>}
          </label>
        )}
        {!isCreateble ? (
          <ReactSelect
            // menuIsOpen={true}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            styles={customStyles}
            options={options}
            menuPlacement="auto"
            {...register}
            isMulti={isMulti}
            name={name}
            ref={ref as unknown as undefined}
            components={{ Option }}
            onChange={onChange}
            value={selectedValue || (value as valueType)}
            defaultValue={defaultValue}
            isDisabled={disabled}
            isClearable={isClearable}
            noOptionsMessage={() => "No Option"}
            isSearchable={isSearchable}
            className={`${className} ${error ? "!border-Darkred" : ""}`}
          />
        ) : (
          <CreatableSelect
            placeholder={placeholder}
            onCreateOption={onCreateOption}
            styles={customStyles}
            options={options}
            menuPlacement="auto"
            {...register}
            isMulti={isMulti}
            name={name}
            components={{ Option }}
            ref={ref as unknown as undefined}
            onChange={onChange}
            value={value}
            className={className + "h-94"}
            isDisabled={disabled}
            noOptionsMessage={() => "No Option"}
          />
        )}

        {error && (
          <p className="text-Darkred text-sm font-normal leading-150% mt-1">
            {error}
          </p>
        )}
      </div>
    );
  }
);

export default Select;
