import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import RouteComponent from "route/route";
import { tokensSelector } from "redux/ducks/token";
import Header from "components/layouts/Header";
import Sidebar from "components/layouts/Sidebar";
import { TABLET_VIEW } from "helper/common/constant";
import { profileSelector, setProfile } from "redux/ducks/profile";
import { getPreCheckInData, updatePermissionDuck } from "services/dashboard";
import { setLog } from "redux/ducks/logs";
import Loader from "components/common/Loader";

const Layout = (props: PropsFromRedux) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector(profileSelector);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [selectedNavigationTab, setSelectedNavigationTab] = useState<string>("");

  // const getTabValue = (role: string, tabs: Array<INavOptions>) => {
  //   const sideBarTabs = [];
  //   const apiPermissions = Object.keys(role);
  //   for (let i = 0; i < tabs.length; i++) {
  //     const navBackEndKey = tabs[i].moduleName;
  //     const byPass =
  //       process.env.REACT_APP_PERMISSION_BYPASS === "false" ? false : true;
  //  if (isAdmin) {
  //       isAuthRequired = true;
  //     } else {
  //       for (let j = 0; j < navBackEndKey.length; j++) {
  //         if (allModuleNames.includes(navBackEndKey[j])) {
  //           isAuthRequired = true;
  //           if (apiPermissions.includes(navBackEndKey[j])) {
  //             isTabVisible = true;
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const fetchPermission = async () => {
    try {
      const updatePermission = await updatePermissionDuck(profileData.id.toString())
      const logsResponse = await getPreCheckInData()
      if (logsResponse.status === 200) {
        if (logsResponse.data.data.length !== 0) {

          dispatch(setLog({
            type: logsResponse.data.data[0].type,
            date: logsResponse.data.data[0].date,
            jobId: logsResponse.data.data[0].jobId,
            time: logsResponse.data.data[0].time,
          }))
        }
      }
      if (updatePermission.status === 200) {

        dispatch(
          setProfile({
            email: updatePermission.data.data.user.email,
            name: updatePermission.data.data.user.name,
            userName: updatePermission.data.data.user.username,
            roles: updatePermission.data.data.roles,
            permissions: updatePermission.data.data.permissions,
            companyId: updatePermission.data.data.companyId,
            phone: updatePermission.data.data.user.phone,
            image:
              updatePermission.data.data.user.avatar !== null
                ? updatePermission.data.data.user.avatarUrl
                : null,
            id: updatePermission.data.data._id,
            companyImage: updatePermission.data.data.companyDetails.companyLogo,
            address: updatePermission.data.data.companyDetails?.address ? updatePermission.data.data.companyDetails.address : '',
            rolesDetails: updatePermission.data.data.rolesDetails,
            askedToConnect: updatePermission.data.data.connectionStatus,
            companyName: updatePermission.data.data.companyDetails.name,
          })
        );
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!props.tokens.accessToken) {
      navigate("/login", { replace: true });
    } else {
      // show dashboard active (exceptional conditions)
      fetchPermission()
      const path = location.pathname === "/" ? "dashboard" : location.pathname;
      setSelectedNavigationTab(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleNavigation = (path: string) => {
    setSelectedNavigationTab(path);
    navigate(path);
  };

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= TABLET_VIEW &&
        setIsSidebarCollapsed(true);
    }
    // On load to collapse sidebar 
    handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  return (
    // <Suspense fallback={<GlobalLoader />}>
    <Suspense fallback={<Loader />}>
      <Outlet />
      <div
        className="flex-1 flex flex-col overflow-hidden"
        onClick={() => {
          if (!isSidebarCollapsed && window.innerWidth <= TABLET_VIEW) {
            setIsSidebarCollapsed(true);
          }
        }}
      >
        <Header key={JSON.stringify(profileData)} onToggleSidebar={toggleSidebar} />
        <div className="flex h-screen">
          <Sidebar
            key={JSON.stringify(profileData)}
            collapsed={isSidebarCollapsed}
            activeSection={selectedNavigationTab}
            onNavigate={handleNavigation}
            onToggleSidebar={toggleSidebar}
          />
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-bodybg mt-84 xs:pl-0 md:pl-94 xl:pl-0">
            <div className="pl-6 pt-5 pr-30 pb-30 xs:pl-4 md:pl-6 xs:pt-4 md:pt-5 xs:pr-4 md:pr-6 lg:pr-30 xs:pb-4 md:pb-6 lg:pb-30">
              <RouteComponent />
            </div>
          </main>
        </div>
      </div>
    </Suspense>
  );
};

const mapStateToProps = createStructuredSelector({
  tokens: tokensSelector,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Layout);
