import { AccessToken, RefreshToken } from 'types/auth/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  refreshToken: null,
};
export interface ItokenInitialRedux {
  accessToken: null | AccessToken;
  refreshToken: null | RefreshToken;
}

export const tokenSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokens: (state: ItokenInitialRedux, action: PayloadAction<ItokenInitialRedux>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setAccessToken: (
      state: { accessToken: null | AccessToken },
      action: PayloadAction<AccessToken>
    ) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (
      state: { refreshToken: null | RefreshToken },
      action: PayloadAction<RefreshToken>
    ) => {
      state.refreshToken = action.payload;
    },
    removeTokens: (state: ItokenInitialRedux) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const tokensSelector = (state: { tokens: ItokenInitialRedux }) => state.tokens;

const { actions, reducer } = tokenSlice;

export const { removeTokens, setAccessToken, setRefreshToken, setTokens} =
  actions;

export default reducer;
