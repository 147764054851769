import { HeaderProps } from "types/layout";
import { FaBars } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import { Exiticon, Notification, Profileicon } from "icons";
import { useNavigate } from "react-router-dom";
import Logout from "../logout/Logout";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "redux/ducks/profile";
import { AiOutlineClose } from "react-icons/ai";
import Image from "components/common/Image";
import { DEFAULT_IMAGE, ErrorMessage, roleType } from "helper/common/constant";
import {
  clearAllNotificationsApi,
  clearNotificationsById,
  getNotification,
  readNotification,
} from "services/dashboard";
import { dispatchToast } from "helper/common/function";
import { FaLongArrowAltRight } from "react-icons/fa";
import { notificationSelector, setNotificationCount } from "redux/ducks/notification";
import { notificationList } from "types/dashboard/dashboard";
import Loader from "components/common/Loader";
import { useOutsideClick } from "customeHooks/hook";
import { tokensSelector } from "redux/ducks/token";
import ShortcutLog from "./ShortcutLog";

const Header = ({ onToggleSidebar }: HeaderProps) => {
  const dispatch = useDispatch();
  const notificationCount = useSelector(notificationSelector);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notification, setNotification] = useState<notificationList[]>([]);
  const [loaderNotification, setLoaderNotification] = useState(false);
  const profile = useSelector(profileSelector);
  const navigate = useNavigate();
  const tokens = useSelector(tokensSelector);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const parentProfileRef = useRef<HTMLDivElement | null>(null);
  const parentNotificationRef = useRef<HTMLDivElement | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = () => {
    setIsProfileOpen(false);
  };
  const handleNotificationOutsideClick = () => {
    setIsNotificationOpen(false)
  }
  useOutsideClick(profileRef, handleClickOutside, isProfileOpen, parentProfileRef);
  useOutsideClick(notificationRef, handleNotificationOutsideClick, isNotificationOpen, parentNotificationRef);

  const getNotificationList = async () => {
    try {
      setLoaderNotification(true);
      const number = await getNotification({ limit: 3 });
      dispatch(
        setNotificationCount({
          count: number.data.data.badgeCount
        })
      );
      setCount(number.data.data.data.totalResults)
      setNotification(number.data.data.data.results);
      setLoaderNotification(false);
    } catch (error) {
      setLoaderNotification(false);
      // dispatchToast(ErrorMessage.SomethingWentWrong, 'error')
    }
  };
  const clearNotification = async (index: string) => {
    try {
      const clearNotification = await clearNotificationsById(index);
      if (clearNotification.status === 200) {
        dispatchToast(
          clearNotification.data.message,
          clearNotification.data.responseType
        );
        if (clearNotification.data.data.acknowledged === true) {
          await getNotificationList();
        }
      }
    } catch (error) {
      dispatchToast(ErrorMessage.SomethingWentWrong, 'error')
    }
  };

  const handleRead = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string[], redirect: string) => {
    e.stopPropagation();
    setIsProfileOpen(false);
    try {
      const param = {
        ids: [...id],
      };
      const read = await readNotification(param);
      if (read.status === 200) {
        dispatchToast(read.data.message, read.data.responseType);
        if (read.data.data.acknowledged === true) {
          await getNotificationList();
        }
        if (id.length === 1) {
          navigate(`/${redirect}`);
        }
      }
    } catch (error) {
      dispatchToast(ErrorMessage.SomethingWentWrong, 'error')
    }
  };

  const clearAllNotifications = async () => {
    try {
      const clearAllNotification = await clearAllNotificationsApi();
      if (clearAllNotification.status === 200) {
        dispatchToast(
          clearAllNotification.data.message,
          clearAllNotification.data.responseType
        );
        if (clearAllNotification.data.data.acknowledged === true) {
          await getNotificationList();
        }
      }
    } catch (error) {
      setNotification([]);
    }
  };
  useEffect(() => {
    tokens.accessToken && getNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="bg-white flex items-center justify-between py-4 px-4 sm:px-6 fixed left-0 right-0 top-0 border-b border-solid border-loginBorder z-999">
      <div className="flex items-center gap-5 sm:gap-30">
        <span
          title={"click me"}
          onClick={onToggleSidebar}
          className="p-2 border border-solid border-loginBorder rounded-lg cursor-pointer"
        >
          <FaBars className="text-2xl" />
        </span>
        <img src="/assets/images/logo.svg" alt="" className="w-135 h-50" />
      </div>
      <div className="flex items-center gap-1 sm:gap-5 relative">
        <div>
          {(profile.rolesDetails.type === roleType.staff || profile.rolesDetails.type === roleType.worker) && <ShortcutLog />}
        </div>
        <div
          className={`w-10 h-10 rounded-full cursor-pointer  border-solid border-black transition-all ease-in-out duration-300 relative`}
          onClick={() => {
            setIsProfileOpen(false)
            setIsNotificationOpen(!isNotificationOpen)
            if (!isNotificationOpen) getNotificationList()
          }}
          ref={parentNotificationRef}
        >
          <div className="relative h-full w-full flex items-center justify-center select-none">
            {notificationCount !== 0 && (
              <p className="absolute top-0 left-5 bg-Darkred p-1 rounded-full h-5 w-5 flex items-center justify-center text-white text-xs">
                {notificationCount}
              </p>
            )}
            <Notification />
          </div>
          {isNotificationOpen ? (
            <div
              className="absolute bg-white rounded-lg border border-solid border-loginBorder shadow-dropdownshadow w-80 sm:w-96 -right-8 sm:right-2 top-10"
              ref={notificationRef}
            >
              <div className="bg-Darkred py-2.5 text-center rounded-t-lg">
                <span className="text-white">Notifications</span>
              </div>
              {!loaderNotification ?
                notification.length > 0 ? (
                  <div className="py-2">
                    <div className="flex items-center justify-between mt-1.5 mb-2.5 px-4">
                      <span
                        className={`block leading-none text-sm border-b-2 border-solid border-Darkred text-Darkred font-semibold`}
                        onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                          const allId = notification.map(
                            (data: { _id: string }) => data._id
                          );
                          handleRead(e, allId, '');
                        }}
                      >
                        Mark as read
                      </span>
                      <span
                        className={`block leading-none text-sm border-b-2 border-solid border-Darkred text-Darkred font-semibold`}
                        onClick={clearAllNotifications}
                      >
                        Clear All
                      </span>
                    </div>
                    {notification.map((data: any, index: number) => (
                      <ul
                        className={`flex items-center justify-between gap-4 mt-1 pr-4 ${data.isRead === false ? "bg-Darkred/10" : "bg-white"
                          }`}
                        key={index}
                      >
                        <li
                          className={`pl-4 py-1.5 flex gap-4`}
                          onClick={(e) => {
                            !data.isRead && handleRead(e, [data._id], data.redirectionType)
                          }}
                        >
                          <div className={`group w-10 h-10`}>
                            <Image
                              src={data.avatarUrl instanceof Blob ? URL.createObjectURL(data.avatarUrl) : data.avatarUrl}
                              defaultImage={DEFAULT_IMAGE.defaultAvatar}
                              imageClassName={'rounded-full h-full w-full'}
                            />
                          </div>
                          <div className="w-[calc(100%-56px)]">
                            <p className="text-base font-semibold">{data.title}</p>
                            <p className="text-sm truncate2">{data.message}</p>
                          </div>
                        </li>
                        <button
                          className="text-dark text-xl hover:text-primary hover:rotate-180 transition-all duration-500 bg-Darkred rounded-full p-0.5"
                          onClick={() => clearNotification(data._id)}
                        >
                          <AiOutlineClose className="text-10 text-white" />
                        </button>
                      </ul>
                    ))}
                    {count && count > 3 ? (

                      <div className="flex items-center justify-center gap-3 my-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsNotificationOpen(false);
                          navigate(`/dashboard/notification`)
                        }}
                      >
                        <span className="leading-none text-sm border-b-2 border-solid border-Darkred text-Darkred font-semibold">
                          View All Notification
                        </span>
                        <FaLongArrowAltRight className="text-Darkred" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="h-20 flex items-center justify-center px-4 py-2">
                    <p>Notifications Not Found</p>
                  </div>
                ) : (
                  <div className={`${loaderNotification && 'h-350'}`}>
                    <Loader className="top-12" />
                  </div>
                )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`w-10 h-10 rounded-full cursor-pointer  border-solid border-black transition-all ease-in-out duration-300`}
          onClick={() => {
            setIsNotificationOpen(false);
            setIsProfileOpen((prev) => !prev);
          }}
          ref={parentProfileRef}
        >
          <Image
            src={typeof profile.image === "string" ? profile.image : ""}
            defaultImage={DEFAULT_IMAGE.defaultAvatar}
            imageClassName={"rounded-full"}
          />
        </div>
        {isProfileOpen && (
          <div
            className="absolute bg-white rounded-lg border border-solid border-loginBorder shadow-dropdownshadow w-150 right-0 top-12 px-3 py-2"
            ref={profileRef}
          >
            <ul>
              <li>
                <div className="flex items-center gap-2.5 px-1 py-2 cursor-pointer">
                  <Profileicon />
                  <span
                    className={
                      "text-black text-base font-normal leading-normal block"
                    }
                    onClick={() => {
                      navigate("/dashboard/profile");
                      setIsProfileOpen((prev) => !prev);
                    }}
                  >
                    Profile
                  </span>
                </div>
              </li>
              <li>
                <div
                  className="flex items-center gap-2.5 px-1 py-2 cursor-pointer "
                  onClick={() => setIsLogoutOpen(true)}
                >
                  <Exiticon />
                  <span className="text-black text-base font-normal leading-normal block">
                    Logout
                  </span>
                </div>
              </li>
            </ul>
            <Logout
              isLogoutOpen={isLogoutOpen}
              setIsLogoutOpen={setIsLogoutOpen}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
