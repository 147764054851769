import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IForgetPassword } from "types/auth/auth";
import Button from "components/common/button/Button";
import { forgetPassword } from "services/auth";
import { toastShow } from "redux/ducks/toast";
import { dispatchToast } from "helper/common/function";

interface MyLocationState {
  email?: string;
}

const EmailSent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | undefined>("");

  useEffect(() => {
    const userEmail: string | undefined = (location.state as MyLocationState)
      ?.email;
    setUserEmail(userEmail);
  }, [location.state]);

  const {
    handleSubmit,
  } = useForm<IForgetPassword>({
    defaultValues: {
      email: userEmail,
    },
  });

  const onSubmit: SubmitHandler<IForgetPassword> = async (data) => {
    setLoading(true);
    try {
      if (userEmail !== "") {
        data.email = `${userEmail}`;
        const responseData = await forgetPassword(data);
        if (responseData.status === 200) {
          //logic for success
          dispatchToast(responseData.data.message,responseData.data.responseType)
        }
      }
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
      dispatch(toastShow({ message: "Something went wrong", type: "error" }));
    }
  };

  return (
    <div className="relative bg-bodybg w-full h-screen">
      <div className="relative top-2/4 -translate-y-2/4 lg:top-0 lg:translate-y-0 lg:py-10 2xl:top-2/4 2xl:-translate-y-2/4 2xl:py-0">
        <div className="relative text-center">
          <img
            src="/assets/images/logo.svg"
            className="mx-auto mb-61 xs:px-5 md:px-0 xs:max-w-75% sm:max-w-full"
            alt="logo"
          />
        </div>
        <div className="flex justify-center rounded-xl">
          <div className="relative md:max-w-300 lg:max-w-375 w-full rounded-l-lg border border-solid border-loginBorder border-r-0 hidden md:block">
            <img
              src="/assets/images/Login-Banner.png"
              className="relative h-full w-full object-cover object-center rounded-l-lg"
              alt="logo"
            />
          </div>
          <div className="bg-white flex items-center xs:px-5 xs:py-5 md:py-0 md:px-10 lg:px-84 xs:max-w-full xs:mx-5 md:mx-0 md:max-w-400 lg:max-w-570 w-full rounded-r-lg border border-solid border-loginBorder md:border-l-0 xs:border-l">
            <div className="relative w-full">
              <h2 className="text-BrandBlack text-3xl font-medium leading-normal xs:mb-5 md:mb-6 lg:mb-30">
                Email Sent
              </h2>
              <p className="text-BrandBlack text-base  lg:text-lg font-normal leading-6 xs:mb-5 md:mb-7 lg:mb-12">
                If the email address has an account, an email will be sent with
                a link to reset your password
              </p>
              <div className="flex items-center flex-wrap gap-1">
                <p className="text-BrandBlack text-base lg:text-lg font-normal leading-6">
                  Email sent to :
                </p>
                <Link
                  to="/forgetPassword"
                  className="text-BrandBlack text-base lg:text-lg font-medium leading-6"
                >
                  {userEmail}
                </Link>
              </div>
              <div className="xs:mt-5 md:mt-7 lg:mt-87">
                <h4 className="text-BrandBlack text-base lg:text-lg font-normal leading-6">
                  Didn’t receive email? Request a resend below.
                </h4>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="xs:mt-7 md:mt-7 lg:mt-12">
                  <Button
                    title="Re-Send Email"
                    type="submit"
                    isLoading={isLoading}
                  />
                  <div className="relative flex items-center gap-1 justify-center mt-30">
                    <img src="/assets/images/arrow-left-short.svg" alt="" />
                    <Link
                      to="/login"
                      className="text-BrandBlack text-base leading-150% font-normal underline"
                    >
                      Back To Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
