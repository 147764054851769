import CheckBox from "components/common/checkBox/CheckBox";
import Input from "components/common/formInput/Input";
import PasswordInput from "components/common/formInput/PasswordInput";
import { ILoginForm } from "types/auth/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation/auth/authValidation";
import Button from "components/common/button/Button";
import { login } from "services/auth";
import { setTokens, tokensSelector } from "redux/ducks/token";
import { DEFAULT_IMAGE, EXPIRATION_TIME } from "helper/common/constant";
import { setProfile } from "redux/ducks/profile";
import { toastShow } from "redux/ducks/toast";

const Login = () => {
  // ======================= Use States ============================//
  const dispatch = useDispatch();
  const [loginProcess, setLoginProcess] = useState(false);
  const token = useSelector(tokensSelector);
  //================================================================//
  //======================= Constants ============================//

  const intialLoginValue = {
    isRememberMe: false,
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ILoginForm>({
    defaultValues: intialLoginValue,
    resolver: yupResolver(loginSchema),
  });

  //  ================================================================//

  useEffect(() => {
    const saveData = localStorage.getItem("rememberMe")
    if (saveData !== null) {
      const formattedData = JSON.parse(saveData);
      const currentTime = new Date().getTime();

      if (currentTime - formattedData.timestamp <= EXPIRATION_TIME) {
        setValue('email', formattedData.email);
        setValue('password', formattedData.password);
        setValue('isRememberMe', formattedData.isRememberMe);
      } else {
        // Clear expired rememberMe data
        localStorage.removeItem("rememberMe");
      }
    }
  }, [setValue])

  //   ======================= Submit Handler ============================//

  const onSubmit = async (values: ILoginForm) => {
    if (values.isRememberMe) {
      const saveData = {
        ...values,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem("rememberMe", JSON.stringify(saveData));
    } else {
      localStorage.removeItem("rememberMe");
    }
    setLoginProcess(true);
    const params = {
      email: values.email,
      password: values.password,
    };
    try {
      const loginResponse = await login(params);
      if (loginResponse.status === 200) {
        dispatch(
          setTokens({
            accessToken: loginResponse.data.data.access_token,
            refreshToken: null,
          })
        );

        dispatch(
          setProfile({
            email: loginResponse.data.data.user.email,
            name: loginResponse.data.data.user.name,
            userName: loginResponse.data.data.user.username,
            roles: loginResponse.data.data.user.roles,
            permissions:loginResponse.data.data.user.permissions,
            companyId:loginResponse.data.data.user.companyId,
            phone:loginResponse.data.data.user.phone,
            image:
              loginResponse.data.data.user.avatar !== null
                ? loginResponse.data.data.user.avatarUrl
                : null,
            id: loginResponse.data.data.user._id,
            companyImage:loginResponse.data.data.user.companyDetails.companyLogo,
            address:loginResponse.data.data.user.companyDetails?.address ? loginResponse.data.data.user.companyDetails.address : '',
            rolesDetails:loginResponse.data.data.user.rolesDetails,
            askedToConnect:loginResponse.data.data.user.connectionStatus,
            companyName:loginResponse.data.data.user.companyDetails.name,
          })
        );
      }
      if (loginResponse.data.statusCode === 400) {
        if (loginResponse.data.message.includes("Account")) {
          setError("email", { message: loginResponse.data.message });
        } else if (loginResponse.data.message.includes("credentials")) {
          setError("password", { message: loginResponse.data.message });
        } else {
        }
      }
      setLoginProcess(false);
    } catch (err) {
      dispatch(toastShow({ message: "Something went wrong", type: "error" }));
      setLoginProcess(false);
    }
  };

  useEffect(() => {
    // console.log(token, "token");
  }, [token]);

  return (
    <div className="relative bg-bodybg w-full h-screen">
      <div className="relative top-2/4 -translate-y-2/4 lg:top-0 lg:translate-y-0 lg:py-10 2xl:top-2/4 2xl:-translate-y-2/4 2xl:py-0">
        <div className="relative text-center">
          <img
            src={DEFAULT_IMAGE.defaultAppLogo}
            className="mx-auto mb-61 xs:px-5 md:px-0 xs:max-w-75% sm:max-w-full"
            alt="logo"
          />
        </div>
        <div className="flex justify-center rounded-xl">
          <div className="relative md:max-w-300 lg:max-w-375 w-full rounded-l-lg border border-solid border-loginBorder border-r-0 hidden md:block">
            <img
              src={DEFAULT_IMAGE.loginBanner}
              className="relative h-full w-full object-cover object-center rounded-l-lg"
              alt="logo"
            />
          </div>
          <div className="bg-white flex items-center xs:px-5 xs:py-5 md:py-0 md:px-10 lg:px-84 xs:max-w-full xs:mx-5 md:mx-0 md:max-w-400 lg:max-w-570 w-full rounded-r-lg border border-solid border-loginBorder md:border-l-0 xs:border-l">
            <div className="relative w-full">
              <h1 className="text-BrandBlack text-3xl font-medium leading-normal mb-5 md:mb-7 lg:mb-10">
                Login
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex gap-6 flex-col">
                  <Input
                    label="Email"
                    placeholder="Enter email"
                    type="text"
                    register={register("email")}
                    className={`${errors.email ? "border-Darkred" : ""}`}
                    error={errors.email?.message as string}
                  />
                  <PasswordInput
                    label="Password"
                    placeholder="Enter password"
                    register={register("password")}
                    className={`${errors.password ? "border-Darkred" : ""}`}
                    error={errors.password?.message}
                  />
                  <div className="flex items-center justify-between xs:mb-8 md:mb-12 lg:mb-84">
                    <CheckBox
                      label="Remember Me"
                      labelClassName=""
                      register={register("isRememberMe")}
                    />
                    <Link
                      to="/forgetPassword"
                      className="text-BrandBlack text-base leading-150% font-normal underline"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <Button
                  title="Login To Your Account"
                  variant="filled"
                  type="submit"
                  isLoading={loginProcess}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
