import React from "react";
import Button from "./button/Button";
import { AiOutlineClose } from "react-icons/ai";
const ConfirmationDialog = ({
  width,
  className,
  isOpen,
  onClose,
  title,
  icon,
  message,
  onConfirm,
  isLoading = false,
  closeIcon = true,
  confirmLabel = "Confirm",
  associationList,
}: {
  title: string;
  icon?: React.ReactNode;
  message: string;
  width?: string;
  children?: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  closeIcon?: boolean;
  confirmLabel?: string
  associationList?: string[];
}) => {
  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 overflow-x-hidden overflow-y-auto z-50 ">
            <div
              className={`relative w-full flex justify-between min-h-screen items-center py-10 mx-auto ${width ? width : "max-w-90% sm:max-w-375"
                }`}
            >
              <div className="fixed inset-0 bg-black/10 backdrop-blur-sm" />
              <div className="rounded-[20px] relative flex flex-col w-full bg-white p-8 ">
                <div className={`relative ${className}`}>
                  <div className="relative flex flex-col gap-3 items-center text-center mb-8">
                    {closeIcon && (
                      <button
                        className="absolute -right-5 -top-5 text-dark text-xl hover:text-primary hover:rotate-180 transition-all duration-500 p-2  rounded-md"
                        onClick={onClose}
                        type="button"
                      >
                        <AiOutlineClose className="text-2xl text-BrandBlack" />
                      </button>
                    )}
                    {icon && (
                      <div className="h-28 w-28 rounded-full bg-primary/10 flex items-center justify-center text-5xl text-primary mb-2 ">
                        {icon}
                      </div>
                    )}
                    <div className="w-10/12">

                      {associationList && associationList.length !== 0 ? (
                        <>
                          <p className="text-lg text-BrandBlack font-normal leading-6">This entity is associated with another module, which is listed below, so it can't be deleted.</p>
                          <div className="pl-30">
                            <div className="flex flex-col items-start">
                              {associationList.map((data) => {
                                return (
                                  <ul className="text-lg text-BrandBlack font-normal leading-6 mt-2">
                                    <li className="list-disc">{data}</li>
                                  </ul>
                                )
                              })}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h2 className="text-lg text-BrandBlack font-normal leading-6">
                            {title}
                          </h2>
                          <p className="text-lg text-BrandBlack font-normal leading-6">
                            {message}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-3 justify-center">
                    <Button
                      onClick={onClose}
                      title={"Cancel"}
                      type="button"
                      variant="outline"
                      className={
                        "!bg-BrandBlack/10 !text-black w-auto px-5"
                      }
                    />
                    <Button
                      onClick={() => {
                        onConfirm();
                      }}
                      title={confirmLabel}
                      type="button"
                      variant="filled"
                      isLoading={isLoading}
                      disabled={associationList ? associationList.length !== 0 : false}
                      className={"w-auto px-5"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ConfirmationDialog;
