import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import tokenReducer from './ducks/token';
import toastReducer from './ducks/toast';
import profileReducer from './ducks/profile';
import logReducer from './ducks/logs';
import notificationReducer from './ducks/notification';
import Cookies from 'cookies-js';
const { CookieStorage } = require('redux-persist-cookie-storage');

const persistConfig = {
  key: 'max_scaffolds',
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 365 * 86400, // Cookies expire after one year
    },
  }),
  whitelist: ['tokens','profile'],
};

const rootReducer = combineReducers({
  tokens: tokenReducer,
  toast: toastReducer,
  profile:profileReducer,
  notification:notificationReducer,
  logs: logReducer,
});

export default persistReducer(persistConfig, rootReducer);