import { ProfileType } from 'types/dashboard/dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ProfileType = {
    name: '',
    userName: '',
    email: '',
    roles: [],
    permissions:[],
    image: '',
    id:0,
    companyId:"",
    companyName:'',
    phone:'',
    rolesDetails:{
        _id:'',
        companyId:'',
        __v:0,
        createdAt:'',
        name:'',
        slug:'',
        type:'',
        updatedAt:'',
    },
    askedToConnect:'',
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state: ProfileType, action: PayloadAction<ProfileType>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

const { actions, reducer } = profileSlice;

export const { setProfile } = actions;

export default reducer;

// Selector
export const profileSelector = (state: { profile: ProfileType }) => state.profile;
