import { ItokenInitialRedux, removeTokens } from "redux/ducks/token";
import axios, { AxiosRequestConfig } from "axios";
import { Store } from "redux";
import { ProfileType } from "types/dashboard/dashboard";
import { ErrorMessage } from "helper/common/constant";

const setupAxios = (store: Store) => {
  axios.interceptors.request.use((request: any) => {
    const storeData = store.getState();
    const tokens: ItokenInitialRedux = storeData.tokens;
    const profile: ProfileType = storeData.profile;
    if (tokens.accessToken) {
      // Add company id for every request in future use
      // request.headers['X-Your-Custom-Header'] = 'YourCustomValue';
      request.headers.Authorization = `Bearer ${tokens.accessToken}`;
      if (profile.companyId) {
        request.headers.companyId = profile.companyId;
      }
    }
    return request;
  });

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    (e) => {
      if (e.response && e.response.data.message === ErrorMessage.InvalidToken) {
        // dispatchToast(ErrorMessage.TokenExpired , "error");
        store.dispatch(removeTokens());
      }
      return e.response;
    }
  );
};

export default setupAxios;

export function axiosGet<T>(url: string, data: T | null = null) {
  return axios.get(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, {
    params: data,
  });
}

export function axiosPost<T>(url: string, data: T | object) {
  return axios.post(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, data);
}

export const axiosConfig = (
  method: string,
  url: string,
  config: any,
  data: object
) => {
  return axios({
    method: method,
    url: `${process.env.SCAFFOLDS_API_URL}${url}`,
    ...config,
    data,
  });
};

export const axiosPatch = (url: string, data: object) => {
  return axios.patch(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, data);
};

export const axiosPut = (url: string, data: object) => {
  return axios.put(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, data);
};

export const axiosDelete = <T>(url: string, data?: T | null) => {
  return axios.delete(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, {
    params: data || null,
  });
};

export const axiosGetBlob = (url: string, data?: AxiosRequestConfig) => {
  return axios.get(`${process.env.REACT_APP_SCAFFOLDS_API_URL}${url}`, data);
};
