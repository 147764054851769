import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "axios/config";

export const getPreData = (param?: object) => {
    return axiosGet(`/job/get-builders`, param);
}

export const getPreDataOfJob = (param: object) => {
    return axiosGet(`/job/get-users`, param);
}

export const createJob = (data: object) => {
    return axiosPost(`/job/create`, data);
};


export const getJobData = (id: string) => {
    return axiosGet(`/job/details/${id}`);
}

export const updateJobData = (param: object) => {
    return axiosPatch(`/job/update`, param);
}

export const getJobListWithBuilderId = (param: object) => {
    return axiosGet(`/job/jobs-with-builder`, param);
}

export const deleteJob = (id: string) => {
    return axiosDelete(`/job/delete/${id}`)
}

export const getJobs = (filter: object) => {
    return axiosGet(`/job/jobs`, filter);
};

export const updateStatus = (param: object) => {
    return axiosPatch(`/job/update-status`,param)
}

export const deleteLeadDetails = (stageId: string) => {
    return axiosGet(`/job/get-erect/${stageId}`)
}