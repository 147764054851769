import React, { useEffect, useState } from "react";
import { IoIosTimer } from "react-icons/io";
import CheckInModal from "./CheckInModal";
import { FaPauseCircle, FaPlayCircle, FaStopCircle } from "react-icons/fa";
import { dispatchToast } from "helper/common/function";
import moment from "moment";
import { saveActivityLog } from "services/timeSheet";
import { useDispatch, useSelector } from "react-redux";
import { logSelector, setLog } from "redux/ducks/logs";

const ShortcutLog = () => {
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(new Date());
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [jobId, setJobId] = useState<string>();
  const dispatch = useDispatch();
  const logData = useSelector(logSelector);
  const [currentPosition, setCurrentPosition] = useState<{
    latitude: null | string;
    longitude: null | string;
  }>({ latitude: null, longitude: null });

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const response = await activityFunc("CHECK-IN", values.jobId);
    if (response === true) {
      setJobId(values.jobId);
      setIsStartTimer(true);
    }
    setShowCheckInModal(false);
    setIsLoading(false);
  };
  useEffect(() => {
    setInterval(() => setSeconds(new Date()), 1000);
  }, []);

  useEffect(() => {
    if (logData?.jobId?.length !== 0) {
      if (logData.type === "CHECK-OUT") {
        setIsStartTimer(false);
      } else {
        setJobId(logData.jobId);
        setIsStartTimer(true);
      }
    }
  }, [logData]);

  useEffect(() => {
    const successCallback = (position: any) => {
      const { latitude, longitude } = position.coords;
      setCurrentPosition({ latitude: latitude, longitude: longitude });
    };

    const errorCallback = (error: any) => {
      console.error("Error getting current position:", error.message);
    };
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  const handleStart = async () => {
    await activityFunc("BREAK-OUT");
  };

  const handlePause = async () => {
    await activityFunc("BREAK-IN");
  };

  const handleReset = async () => {
    await activityFunc("CHECK-OUT");
    setIsStartTimer(false);
  };

  const activityFunc = async (type: string, id?: string) => {
    setIsLoading(true);
    if (currentPosition.latitude === null) {
      dispatchToast("Please Allow location access", "error");
      setIsLoading(false);
      return;
    }
    const param = {
      jobId: id || jobId,
      type: type,
      date: moment.utc().format("DD-MM-yyyy"),
      time: moment().format("HH:mm:ss"),
      location: currentPosition,
    };
    try {
      const apiResponse = await saveActivityLog(param);
      dispatchToast(apiResponse.data.message, apiResponse.data.responseType);
      if (apiResponse.status === 200) {
        dispatch(
          setLog({
            jobId: param.jobId || "",
            type: param.type,
            date: param.date,
            time: param.time,
          })
        );
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  return (
    <div>
      <div className="hidden sm:flex items-center gap-2 border border-solid border-loginBorder py-2 px-4 rounded-md">
        {seconds.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })}
        {!isStartTimer ? (
          <div
            className="relative text-2xl flex items-center justify-center select-none"
            title="Start Timer"
            onClick={() => setShowCheckInModal(true)}
          >
            <IoIosTimer />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <div className="flex">
              {logData.type === "CHECK-IN" || logData.type === "BREAK-OUT" ? (
                <button onClick={handlePause}>
                  <FaPauseCircle />
                </button>
              ) : (
                <button onClick={handleStart}>
                  <FaPlayCircle />
                </button>
              )}
            </div>
            {(logData.type === "CHECK-IN" || logData.type === "BREAK-OUT") && (
              <button onClick={handleReset}>
                <FaStopCircle />
              </button>
            )}
          </div>
        )}
      </div>

      {showCheckInModal && (
        <CheckInModal
          isLoading={isLoading}
          isOpen={showCheckInModal}
          onClose={() => setShowCheckInModal(false)}
          onSubmit={handleSubmit}
          title={"Check In Modal"}
          submitBtnTitle={"Check In"}
        />
      )}
    </div>
  );
};

export default ShortcutLog;
