import { useEffect, useState } from "react";
import { SideBarProps } from "types/layout";
import SideBarMenu from "./SideBarMenu";
import { getUserRole, userRolesCategories } from "utils/navigation.utils";
import { useSelector } from "react-redux";
import { profileSelector } from "redux/ducks/profile";
import { NavigationOptions } from "route/Navigation";

const Sidebar = ({
  collapsed,
  activeSection,
  onNavigate,
  onToggleSidebar,
}: SideBarProps) => {
  const profileData = useSelector(profileSelector);
  const [navigationList, setNavigationList] = useState(NavigationOptions);
  const [isSubMenuVisible, setSubMenuVisible] = useState<string | null>(null);

  useEffect(() => {
    const [data, flag]: any = getUserRole(
      profileData.rolesDetails.type,
      profileData.permissions
    );
    let role: string[] = [];
    if (flag === "existing") {
      role = userRolesCategories[data];
    } else {
      data.forEach((subArray: any) => {
        const keyName = Object.keys(subArray)[0];
        role.push(keyName);
      });
    }

    let indexOfMax = role.indexOf("Max");
    if (indexOfMax !== -1) {
      role[indexOfMax] = "Max-Component";
    }

    const filterdSideBarItem = navigationList.filter((item) => {
      if (item.isAllow) {
        if (role?.includes(item.title)) {
          return item;
        } else {
          return false;
        }
      }
      return false;
    });
    setNavigationList(filterdSideBarItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`bg-white h-screen border-r border-solid border-0 border-r-loginBorder py-4 sm:py-6 transition-all ease-in-out duration-300 overflow-hidden z-50 xs:fixed xs:top-0 xs:left-0 xl:static ${
        collapsed ? "xs:w-0 md:w-94 px-0 md:px-6" : "w-255 px-4 md:px-6"
      }`}
    >
      <ul className=" h-full w-full pt-84 overflow-auto sidebar-scroll">
        {navigationList.map((menuData, index) => {
          if (menuData.isAllow === true) {
            let active = false;
            const currentPath = activeSection?.replace("/", "");
            const lengthOfPath = currentPath?.split("/");

            if (menuData.isAllow && menuData.subNavigationOptions) {
              active = menuData.subNavigationOptions.some((data) => {
                if (
                  lengthOfPath?.length >= 3 &&
                  lengthOfPath?.includes(data.value)
                ) {
                  while (lengthOfPath.length !== 2) {
                    lengthOfPath.pop();
                  }
                  activeSection = "/" + lengthOfPath?.slice(0, 3).join("/");
                  return true;
                }
                return currentPath?.endsWith(data.value);
              });
            } else {
              active = currentPath?.startsWith(menuData.value);
            }

            return (
              <li className="mb-1.5" key={index}>
                <SideBarMenu
                  menuData={menuData}
                  collapsed={collapsed}
                  isActive={active}
                  onNavigate={onNavigate}
                  isSubMenuVisible={isSubMenuVisible}
                  setSubMenuVisible={setSubMenuVisible}
                  activePath={activeSection}
                  onToggleSidebar={onToggleSidebar}
                />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
