import { store } from "redux/Store";
import { toastShow } from "redux/ducks/toast";
import libphonenumber from "google-libphonenumber";
import {
  DEFAULT_IMAGE,
  DEFAULT_SUPPORTED_IMAGE_FORMAT,
  NESTED_ROUTE,
  SUPPORTED_ATTACHMENT_FORMAT,
  SUPPORTED_ATTACHMENT_FORMAT_ARRAY,
  UPLOAD_IMAGE_SIZE,
  defaultDate,
} from "./constant";
import { ModulePermissions } from "types/auth/auth";
import heic2any from "heic2any";
import { getJobData } from "services/job";

export function truncateString(str: string, num: number) {
  if (str !== null ?? str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const customDateFormat = (
  dateStr: string | Date,
  specific?: Intl.DateTimeFormatOptions
) => {
  return new Date(dateStr as Date).toLocaleString("en", {
    year: specific?.year || "numeric",
    month: specific?.month || "long",
    day: specific?.day || "numeric",
    weekday: specific?.weekday,
    hour: specific?.hour,
    minute: specific?.minute,
  });
};

export const dispatchToast = (
  message: string,
  type: "success" | "error" | "info" | "warning" | null
) => {
  store.dispatch(toastShow({ message, type }));
};

export const isValidPhoneNumber = (value: string) => {
  if (!value) return false;

  try {
    const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil?.parse("+" + value, "");
    return phoneNumberUtil.isValidNumber(parsedNumber);
  } catch (error) {
    return false;
  }
};

export function removeCountryCode(
  phoneNumber: string,
  phoneUtil: any
): string | null {
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, "+1"); // "+1" default region
    // if (phoneUtil.isValidNumber(parsedNumber)) {
    const nationalSignificantNumber =
      phoneUtil.getNationalSignificantNumber(parsedNumber);
    return nationalSignificantNumber;
    // }
  } catch (e) {
    console.error(e);
  }
  return null;
}

export const separateCountryCodePhone = (phoneValue: string) => {
  let countryCode;
  let phone;

  const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    if (!!phoneValue) {
      const parsedNumber = phoneNumberUtil.parse("+" + phoneValue, "");
      // if (phoneNumberUtil.isValidNumber(parsedNumber)) {
      const tempCountryCode = parsedNumber?.getCountryCode();
      countryCode = tempCountryCode?.toString()!;
      const strippedPhoneNumber = removeCountryCode(
        "+" + phoneValue,
        phoneNumberUtil
      );

      phone = strippedPhoneNumber || "";
      // }
    }

    if (!!countryCode && !!phone) {
      return { countryCode, phone };
    } else {
      return { countryCode: "", phone: "" };
    }
  } catch (error) {
    return { countryCode: "", phone: "" };
  }
};

export const getAddressOnSearch = async (
  address: string,
  getOnlyAddress: boolean = false
) => {
  try {
    const placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request: google.maps.places.TextSearchRequest = {
      query: address,
    };
    return new Promise((resolve, reject) => {
      placesService.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const places = results;
          const locationData = places?.map((place) => {
            if (getOnlyAddress) {
              return place?.name + ", " + place?.formatted_address;
            } else {
              return {
                address: place?.name + ", " + place?.formatted_address,
                location: {
                  latitude: place?.geometry?.location?.lat(),
                  longitude: place?.geometry?.location?.lng(),
                },
              };
            }
          });
          resolve(locationData);
        } else {
          reject([status]);
        }
      });
    });
  } catch (error) {
    return [];
  }
};

export const customRandomNumberGenerator = (max?: number | null) => {
  if (max) {
    return Math.floor(Math.random() * max) + 1;
  }
  return Math.floor(Math.random() * 100000) + 1;
};
export const getDefaultImage = (
  mimeType: SUPPORTED_ATTACHMENT_FORMAT,
  showDefaultImageFormat?: boolean
) => {
  if (SUPPORTED_ATTACHMENT_FORMAT.PDF === mimeType)
    return DEFAULT_IMAGE.defaultPDF;
  if (
    SUPPORTED_ATTACHMENT_FORMAT.XLS === mimeType ||
    SUPPORTED_ATTACHMENT_FORMAT.XLSX === mimeType
  )
    return DEFAULT_IMAGE.defaultXLS;

  if (showDefaultImageFormat) return DEFAULT_IMAGE.defaultIMG;

  if (SUPPORTED_ATTACHMENT_FORMAT.CSV === mimeType)
    return DEFAULT_IMAGE.defaultCsv;
  return null;
};

export const checkValidImageType = (
  imageType: string,
  xlsAllow?: boolean,
  pdfAllow?: boolean
) => {
  if (imageType.trim() !== "") {
    const FILE_FORMAT = DEFAULT_SUPPORTED_IMAGE_FORMAT;
    if (xlsAllow) {
      FILE_FORMAT.push(SUPPORTED_ATTACHMENT_FORMAT.XLS);
      FILE_FORMAT.push(SUPPORTED_ATTACHMENT_FORMAT.XLSX);
    }
    if (pdfAllow) {
      FILE_FORMAT.push(SUPPORTED_ATTACHMENT_FORMAT.PDF);
    }
    return FILE_FORMAT.includes(imageType as SUPPORTED_ATTACHMENT_FORMAT);
  } else {
    return false;
  }
};

export const onGlobalFileChange = async (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const FILE = e.target.files?.[0] || null;
  if (!FILE) return { file: null, message: "File is required" };

  if (!checkValidImageType(FILE.type?.trim(), true, true)) {
    return {
      file: null,
      message: `Invalid File type. only ${SUPPORTED_ATTACHMENT_FORMAT_ARRAY.join(
        ", "
      )} file Format are allowed.`,
    };
  }
  if (FILE.type === SUPPORTED_ATTACHMENT_FORMAT.heif) {
    const newFile = await appleImage(FILE);
    return { file: newFile, message: "" };
  } else {
    return { file: FILE, message: "" };
  }
};

export const onDownloadWithUrl = async (
  url: string | null,
  filename = "download",
  ext: string
) => {
  if (url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}.${ext.toLowerCase()}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      return `Error downloading ${filename}.${ext}`;
    }
  }
};

export const getFileFormatName = (value: string) => {
  if (value.trim() !== "")
    for (const key in SUPPORTED_ATTACHMENT_FORMAT) {
      if (
        SUPPORTED_ATTACHMENT_FORMAT[
          key as keyof typeof SUPPORTED_ATTACHMENT_FORMAT
        ] === value
      ) {
        return key;
      }
    }
  return "";
};

export function formatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getDateDiff(startDate: any, endDate: any) {
  const differenceInMilliseconds = Math.abs(endDate - startDate);
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

  const days = Math.floor(differenceInMilliseconds / oneDayInMilliseconds);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (days < 8) {
    return days + (days > 1 ? " days" : " day");
  } else if (weeks < 5) {
    return weeks + (weeks > 1 ? " weeks" : " week");
  } else if (months < 13) {
    return months + (months > 1 ? " months" : " month");
  } else {
    return years + (years > 1 ? " years" : " year");
  }
}

export function transformAllEnumKeys(obj: object | string) {
  const transformedArray = Object.entries(obj).map(([enumKey, value]) => {
    return [
      enumKey,
      { label: enumKey.split("_").join(" ").toUpperCase(), value },
    ];
  });
  return Object.fromEntries(transformedArray);
}

export const checkButtonPermission = (
  data: ModulePermissions[],
  moduleName: string,
  permissions: any
) => {
  let result: any = {}; // Change the result to be an object instead of an array
  if (!Array.isArray(data)) return result; // Return empty object if data is not an array
  data?.forEach((subArray: any) => {
    const keyName = Object.keys(subArray)[0];
    const actualKey = keyName === "Max" ? "Max-Component" : keyName;
    if (actualKey === moduleName) {
      const allow = subArray[keyName];
      permissions.forEach((check: string) => {
        // Set the permission directly in the result object
        result[check] = allow.includes(check);
      });
    }
  });
  return [result]; // Wrap the result in an array
};

export const parseTimeStringToDateTime = async (
  date: Date,
  timeString: string
) => {
  // Split the time string into hours, minutes, and optionally seconds and milliseconds
  const [timePart, ampm] = timeString.split(" ");
  const [hours, minutes] = timePart.split(":").map(Number);
  const isPM = ampm.toLowerCase() === "pm";

  // Create a new Date object with default date (today)
  const cDate = date;

  // Set the hours based on AM/PM
  // cDate.setHours(isPM && hours < 12 ? hours + 12 : hours === 12 ? hours - 12 : hours);
  cDate.setHours(
    isPM && hours !== 12 ? hours + 12 : hours === 12 && !isPM ? 0 : hours
  );

  // Set the minutes
  cDate.setMinutes(minutes);
  // Return the cDate object
  return new Date(cDate);
};

export const stringToDate = (timeString: string) => {
  const totalBreakMinutes = parseInt(timeString) || 0;
  const hours = Math.floor(totalBreakMinutes / 60);
  const minutes = totalBreakMinutes % 60;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return { formattedTime, hours, minutes };
};

export const appleImage = async (file: File) => {
  if (file.type === "image/heif") {
    let heicBlob = URL.createObjectURL(file);
    let blobRes = await fetch(heicBlob);
    let blob = await blobRes.blob();
    let conversionResult: any = await heic2any({
      blob,
      toType: "image/png",
      quality: 0.8,
    });
    URL.createObjectURL(conversionResult);
    const newFile = new File([conversionResult], file.name, {
      type: "image/png",
    });
    return newFile;
  }
};
export const fileSizeValidation = (file: File) => {
  return file.size <= UPLOAD_IMAGE_SIZE;
};

export const checkPermission = (data: [], path: string, moduleName: string) => {
  const currentPath = path.split("/").filter(Boolean);
  // const replacing = currentPath.map(part => part === 'view' ? 'list' : part);
  const replacing = currentPath.map((part) => {
    if (part === "view") return "list";
    else if (part === "add") return "create";
    else return part;
  });

  const actual = replacing.filter(
    (data) =>
      data === "create" || data === "edit" || data === "view" || data === "list"
  );

  if (!Array.isArray(data)) return true;
  return data
    ?.map((subArray: any) => {
      const keyName = Object.keys(subArray)[0];
      const actualKey = keyName === "Max" ? "Max-Component" : keyName;
      if (actualKey.trim() === moduleName.trim()) {
        const permission = subArray[keyName];
        // Default see all module ho e page
        if (currentPath.length === 1) return true;

        // Default see nested module home page
        if (NESTED_ROUTE.includes(currentPath[0])) return true;

        // Check permission based route access
        return permission.map((check: any) => {
          return actual.includes(check);
        });
      }
      return null;
    })
    .flat()
    .filter(Boolean);
};

export const removeFalseValue = (values: any) => {
  return values
    .map((data: any) => {
      // Filtering keys based on the condition
      const filteredKeys = Object.keys(data).filter((key) => {
        return data[key].date?.getTime() !== defaultDate.getTime();
      });

      // Creating a new object with filtered keys
      const newData = filteredKeys.reduce((obj: any, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

      return newData;
    })
    .filter((data: any) => Object.keys(data).length > 0); // Filtering out empty objects
};

export const setNavigation = async (
  jobId: string,
  receiverFunction: (data: string, id: string) => void
) => {
  if (jobId) {
    try {
      const data = await getJobData(jobId);
      receiverFunction(
        data.data.data.builderData.name,
        data.data.data.builderId
      );
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  }
};
