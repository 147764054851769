import React from "react";
import { CompanyIcon } from "icons";
const Dashboard = React.lazy(() => import("pages/dashboard/Dashboard"));
const Company = React.lazy(() => import("pages/company/Company"));
const User = React.lazy(() => import("pages/administration/User"));
const Profile = React.lazy(() => import("pages/profile/Profile"));
const Builders = React.lazy(() => import("pages/builders/Builders"));
const AddBuilder = React.lazy(() => import("components/builders/AddBuilder"));
const NotFound = React.lazy(() => import("pages/notFound/NotFound"));
const MaxComponents = React.lazy(() => import("pages/maxComponents/MaxComponent"));
const Job = React.lazy(() => import("pages/job/Job"));
const CreateJobs = React.lazy(() => import("components/job/CreateJobs"));
const ViewSite = React.lazy(() => import("components/job/ViewSite"));
const Roles = React.lazy(() => import("pages/administration/Roles"));
const Supplier = React.lazy(() => import("pages/administration/Supplier"));
const RolesForm = React.lazy(() => import("components/adminstration/roles/RolesForm"));
const SuppliersForm = React.lazy(() => import("components/adminstration/roles/RolesForm"));
const JobInfo = React.lazy(() => import("pages/job/jobInfo/JobInfo"));
const AddErect = React.lazy(() => import("components/job/jobInfo/erect/AddErect"));
const Templates = React.lazy(() => import("pages/templates/Template"));
const TemplateForm = React.lazy(() => import("components/templates/TemplateForm"));
const Vehicle = React.lazy(() => import("pages/administration/Vehicle"));
const Delivery = React.lazy(() => import("pages/transport/Delivery"));
const AddDelivery = React.lazy(() => import("components/transport/delivery/AddDelivery"));
const PickUp = React.lazy(() => import("pages/transport/PickUp"));
const OrderMoreGear = React.lazy(() => import("pages/transport/OrderMoreGear"));
const ViewDelivery = React.lazy(() => import("components/transport/delivery/ViewDelivery"));
const AddPickup = React.lazy(() => import("components/transport/pickUp/AddPickup"));
const ViewPickup = React.lazy(() => import("components/transport/pickUp/ViewPickup"));
const AddMoreGear = React.lazy(() => import("components/transport/orderMoreGear/AddMoreGear"));
const ViewOrder = React.lazy(() => import("components/transport/orderMoreGear/ViewOrder"));
const ViewReport = React.lazy(() => import("components/reports/ViewReport"));
const AddReport = React.lazy(() => import("components/reports/AddReport"));
const EditReport = React.lazy(() => import("components/reports/EditReport"));
const NotificationList = React.lazy(() => import("pages/profile/NotificationList"));
const CalendarScheduler = React.lazy(() => import("pages/schedule/CalendarScheduler"));
const ListTImeSheet = React.lazy(() => import("pages/listTimesheet/ListTImeSheet"));
const Hire = React.lazy(() => import("pages/transport/Hire"));
const AddHire = React.lazy(() => import("components/transport/hire/AddHire"));
const ViewHire = React.lazy(() => import("components/transport/hire/ViewHire"));
const TimeSheetDetail = React.lazy(() => import("pages/listTimesheet/TimeSheetDetail"));
const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    moduleName: ["Dashboard"],
  },
  {
    path: "/company",
    name: "Company",
    icon: <CompanyIcon />,
    component: <Company />,
    moduleName: ["Company"],
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: <Profile />,
    moduleName: ["Profile"],
  },
  {
    path: "/dashboard/notification",
    name: "Notification",
    component: <NotificationList />,
    moduleName: ["Notification"],
  },
  {
    path: "/jobs",
    name: "Job",
    component: <Job />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/create",
    name: "Add New Jobs",
    component: <CreateJobs />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/create/:data/:flag",
    name: "Add New Jobs",
    component: <CreateJobs />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/edit/:data",
    name: "Edit Jobs",
    component: <CreateJobs />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/site/view/:id",
    name: "Sites",
    component: <ViewSite />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/view/:jobid",
    name: "Job Info",
    component: <JobInfo />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/erect/add/:jobid",
    name: "Add Erect",
    component: <AddErect />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/erect/edit/:jobid/:selectedErectId",
    name: "Add Erect",
    component: <AddErect />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/:jobid/reports/add",
    name: "Add Report",
    component: <AddReport />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/:jobid/reports/edit/:reportId",
    name: "Add Report",
    component: <EditReport />,
    moduleName: ["Job"],
  },
  {
    path: "/jobs/:jobid/reports/view/:reportId",
    name: "Add Report",
    component: <ViewReport />,
    moduleName: ["Job"],
  },
  {
    path: "/templates",
    name: "Template",
    component: <Templates />,
    moduleName: ["Template"],
  },
  {
    path: "/templates/template-editor/edit/:templateId",
    name: "Template",
    component: <TemplateForm />,
    moduleName: ["Template"],
  },
  {
    path: "/schedules",
    name: "Schedules",
    component: <CalendarScheduler />,
    moduleName: ["Schedule"],
  },
  {
    path: "/transport/deliverys",
    name: "Delivery",
    component: <Delivery />,
    moduleName: ["Transport"],
  },
  {
    parentName: "Delivery",
    parentPath: "/transport/deliverys",
    path: "/transport/deliverys/create",
    name: "Create Delivery",
    component: <AddDelivery />,
    moduleName: ["Transport"],
  },
  {
    parentName: "Delivery",
    parentPath: "/transport/deliverys",
    path: "/transport/deliverys/edit/:deliveryId",
    name: "Edit Delivery",
    component: <AddDelivery />,
    moduleName: ["Transport"],
  },
  {
    path: "/transport/deliverys/view/:selectedId",
    name: "View Delivery",
    component: <ViewDelivery />,
    moduleName: ["Transport"],
  },
  {
    path: "/transport/hire",
    name: "Hire",
    component: <Hire />,
    moduleName: ["Transport"],
  },
  {
    parentName: "hire",
    parentPath: "/transport/hire",
    path: "/transport/hire/create",
    name: "Create Hire",
    component: <AddHire />,
    moduleName: ["Transport"],
  },
  {
    parentName: "hire",
    parentPath: "/transport/hire",
    path: "/transport/hire/edit/:hireId",
    name: "Edit Hire",
    component: <AddHire />,
    moduleName: ["Transport"],
  },
  {
    path: "/transport/hire/view/:selectedId",
    name: "View Hire",
    component:<ViewHire />,
    moduleName: ["Transport"],
  },
  {
    path: "/transport/pickups",
    name: "Pick-up",
    component: <PickUp />,
    moduleName: ["Transport"],
  },
  {
    parentName: "pic-Up",
    parentPath: "/transport/pickups",
    path: "/transport/pickups/create",
    name: "Create Pickups",
    component: <AddPickup />,
    moduleName: ["Transport"],
  },
  {
    parentName: "pic-Up",
    parentPath: "/transport/pickups",
    path: "/transport/pickups/edit/:pickUpId",
    name: "Edit Pickups",
    component: <AddPickup />,
    moduleName: ["Transport"],
  },
  {
    parentName: "pic-Up",
    parentPath: "/transport/pickups",
    path: "/transport/pickups/view/:selectedId",
    name: "View Pickups",
    component: <ViewPickup />,
    moduleName: ["Transport"],
  },
  {
    path: "/transport/order-more-gear",
    name: "Order More Gear",
    component: <OrderMoreGear />,
    moduleName: ["Transport"],
  },
  {
    parentName: "Order More Gear",
    parentPath: "/transport/order-more-gear",
    path: "/transport/order-more-gear/create/:order?/:job?/:builder?",
    name: "Order More Gear",
    component: <AddMoreGear />,
    moduleName: ["Transport"],
  },
  {
    parentName: "Order More Gear",
    parentPath: "/transport/order-more-gear",
    path: "/transport/order-more-gear/edit/:orderMoreId",
    name: "Order More Gear",
    component: <AddMoreGear />,
    moduleName: ["Transport"],
  },
  {
    parentName: "Order More Gear",
    parentPath: "/transport/order-more-gear",
    path: "/transport/order-more-gear/view/:orderMoreId",
    name: "Order More Gear",
    component: <ViewOrder />,
    moduleName: ["Transport"],
  },
  {
    path: "/builder",
    name: "Builders",
    component: <Builders />,
    moduleName: ["Builder"],
  },
  {
    parentName: "Builder",
    parentPath: "/builder",
    path: "/builder/create",
    name: "Create Builder",
    component: <AddBuilder />,
    moduleName: ["Builder"],
  },
  {
    parentName: "Builder",
    parentPath: "/builder",
    path: "/builder/edit/:id",
    name: "Edit Builder",
    component: <AddBuilder />,
    moduleName: ["Builder"],
  },
  {
    path: "/administration/users",
    name: "Users",
    component: <User />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/users/:filterType/:value",
    name: "Users",
    component: <User />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/vehicles",
    name: "Vehicle",
    component: <Vehicle />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/roles",
    name: "Roles & Permissions",
    component: <Roles />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/roles/create",
    name: "Create New Role",
    parentName: "Roles & Permissions",
    parentPath: "/administration/roles",
    component: <RolesForm />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/roles/edit/:id",
    name: "Edit Role",
    parentName: "Roles & Permissions",
    parentPath: "/administration/roles",
    component: <RolesForm />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/suppliers",
    name: "Suppliers",
    component: <Supplier />,
    moduleName: ["Administration"],
  },
  {
    path: "/administration/suppliers/create",
    name: "Create New Suppliers",
    parentName: "Suppliers",
    parentPath: "/administration/suppliers",
    component: <SuppliersForm />,
    moduleName: ["Administration"],
  },
  {
    path: "/max-components",
    name: "Max-Component",
    component: <MaxComponents />,
    moduleName: ["Max-Component"],
  },
  {
    path: "/timesheets",
    name: "TimeSheet",
    component: <ListTImeSheet />,
    moduleName: ["TimeSheet"],
  },
  {
    path: "/timesheets/view/:id",
    name: "TimeSheet",
    component: <TimeSheetDetail />,
    moduleName: ["TimeSheet"],
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   // component: place Componenet here
  //   moduleName: [],
  // },
  {
    path: "*",
    component: <NotFound />,
    moduleName: ["404"],
  },
];

export default routes;
