import { AiFillInfoCircle } from 'react-icons/ai';

const Info = () => {
  return (
    <div className='relative h-9 w-9 min-w-[36px] flex items-center justify-center bg-[#1877F2] text-white text-xl rounded-md' >
      <AiFillInfoCircle />
    </div>
  );
};

export default Info;