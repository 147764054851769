import { useEffect } from "react";

export const useOutsideClick = (
  ref: any,
  callback: () => void,
  isProfileOpen: boolean,
  parent: any
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target === parent?.current || parent.current?.contains(e.target))
        return;
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileOpen]);
};
