import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./routes";
import AuthGuard from "components/guard/AuthGuard";
import {
  IRouteAttribute,
  IRouteComponent,
} from "types/route";
import { useEffect, useRef } from "react";
import { profileSelector } from "redux/ducks/profile";
import { useSelector } from "react-redux";
import { getUserRole, userRolesCategories } from "utils/navigation.utils";

const RouteComponent = (props?: IRouteComponent) => {
  const profileData = useSelector(profileSelector);
  const tabOptions = useRef<string[]>();

  useEffect(() => {
    const [data, flag]: any = getUserRole(profileData.rolesDetails.type, profileData.permissions);
    let role: string[] = []
    if (flag === "existing") {
      role = userRolesCategories[data];
    } else {
      data.forEach((subArray: any) => {
        const keyName = Object.keys(subArray)[0];
        role.push(keyName)
      });
    }
    let indexOfMax = role.indexOf('Max') || 0;
    if (indexOfMax !== -1) {
      role[indexOfMax] = 'Max-Component';
    }

    tabOptions.current = role;
  }, [profileData]);

  return (
    <Routes>
      <Route index element={<Navigate replace to="dashboard" />} />
      {routes &&
        tabOptions.current &&
        routes.map((route: IRouteAttribute, idx: number) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthGuard
                  props={props}
                  modulename={route.moduleName}
                  userTabs={tabOptions.current}
                  path={route.path}
                >
                  {route.component}
                </AuthGuard>
              }
            />
          ) : null;
        })}
    </Routes>
  );
};

export default RouteComponent;
