import { useState } from "react";
import { useForm } from "react-hook-form";
import PasswordInput from "components/common/formInput/PasswordInput";
import Button from "components/common/button/Button";
import { resetPasswordSchema } from "validation/auth/authValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { IResetPassword } from "types/auth/auth";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "services/auth";
import { Check, Cross } from "icons";
import { BsExclamationCircle } from "react-icons/bs";

const ResetPassword = () => {
  const { id, slug } = useParams();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    length: true,
    lowerCase: true,
    upperCase: true,
    digit: true,
    specialCharacter: true,
  });

  const handleNavigation = () => {
    navigate("/login");
  };


  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<IResetPassword>({
    defaultValues: {
      rePassword: "",
      cPassword: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;

    const isLengthValid = newPassword.length >= 8;
    const isUpperCaseValid = /[A-Z]/.test(newPassword);
    const isLowerCaseValid = /[a-z]/.test(newPassword);
    const isDigitValid = /\d/.test(newPassword);
    const isSpecialCharValid = /[!%@#]/.test(newPassword);

    setErrorMessage({
      length: !isLengthValid,
      lowerCase: !isLowerCaseValid,
      upperCase: !isUpperCaseValid,
      digit: !isDigitValid,
      specialCharacter: !isSpecialCharValid,
    });
  };

  const onSubmit = async (values: IResetPassword) => {
    setLoading(true);
    const params = {
      userId: id,
      token: slug,
      password: values.cPassword,
    };
    try {
      const response = await resetPassword(params);
      if (response.status === 200) {
        // logic on success
        navigate("/login");
      }
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-bodybg w-full h-screen">
      <div className="relative top-2/4 -translate-y-2/4 lg:top-0 lg:translate-y-0 lg:py-10 2xl:top-2/4 2xl:-translate-y-2/4 2xl:py-0">
        <div className="relative text-center">
          <img
            src="/assets/images/logo.svg"
            className="mx-auto mb-61 xs:px-5 md:px-0 xs:max-w-75% sm:max-w-full"
            alt="logo"
          />
        </div>
        <div className="flex justify-center rounded-xl">
          <div className="relative md:max-w-300 lg:max-w-375 w-full rounded-l-lg border border-solid border-loginBorder border-r-0 hidden md:block">
            <img
              src="/assets/images/Login-Banner.png"
              className="relative h-full w-full object-cover object-center rounded-l-lg "
              alt="logo"
            />
          </div>
          <div className="bg-white flex items-center xs:px-5 xs:py-5 md:py-5 md:px-10 lg:px-84 xs:max-w-full xs:mx-5 md:mx-0 md:max-w-400 lg:max-w-570 w-full rounded-r-lg border border-solid border-loginBorder md:border-l-0 xs:border-l">
            <div className="relative w-full">
              <div className="relative">
                <h2 className="text-BrandBlack text-2xl md:text-3xl font-medium leading-normal xs:mb-5 md:mb-6 lg:mb-30">
                  Reset your password
                </h2>
                <h5 className="text-BrandBlack text-lg font-normal leading-6 mb-5">
                  Create a new password
                </h5>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className=" relative">
                  <PasswordInput
                    label="New Password"
                    placeholder="Enter password"
                    register={register("rePassword", {
                      required: "Password is required",
                      onChange: (e) => {
                        handleInputChange(e);
                      },
                    })}
                    className={`${
                      errorMessage.length ||
                      errorMessage.upperCase ||
                      errorMessage.lowerCase ||
                      errorMessage.digit ||
                      errorMessage.specialCharacter
                        ? "focus:!border-Darkred focus:!border-t-Darkred"
                        : "focus:!border-gray-600 focus:!border-t-gray-600"
                    }`}
                  />
                  <div className="mt-2.5 mb-3">
                    <div
                      className={`flex items-center gap-1.5 mb-1.5 ${
                        errorMessage.length ? "opacity-50" : "opacity-100"
                      }`}
                    >
                      <span>
                        {Object.keys(dirtyFields).length === 0 ? (
                          <BsExclamationCircle />
                        ) : errorMessage.length ? (
                          <Cross />
                        ) : (
                          <Check />
                        )}
                      </span>
                      <span className="xs:text-xs sm:text-13 lg:text-base">
                        Must be at least 8 characters long
                      </span>
                    </div>
                    <div
                      className={` flex items-center gap-1.5 mb-1.5 ${
                        errorMessage.upperCase && errorMessage.lowerCase
                          ? "opacity-50"
                          : "opacity-100"
                      }`}
                    >
                      <span>
                        {Object.keys(dirtyFields).length === 0 ? (
                          <BsExclamationCircle />
                        ) : errorMessage.upperCase && errorMessage.lowerCase ? (
                          <Cross />
                        ) : (
                          <Check />
                        )}
                      </span>
                      <span className="xs:text-xs sm:text-13 lg:text-base">
                        Must contain an uppercase and lowercase (A, a)
                      </span>
                    </div>
                    <div
                      className={`flex items-center gap-1.5 mb-1.5 ${
                        errorMessage.digit ? "opacity-50" : "opacity-100"
                      }`}
                    >
                      <span>
                        {Object.keys(dirtyFields).length === 0 ? (
                          <BsExclamationCircle />
                        ) : errorMessage.digit ? (
                          <Cross />
                        ) : (
                          <Check />
                        )}
                      </span>
                      <span className="xs:text-xs sm:text-13 lg:text-base">
                        Must contain an number
                      </span>
                    </div>
                    <div
                      className={` flex items-center gap-1.5 mb-1.5 ${
                        errorMessage.specialCharacter
                          ? "opacity-50"
                          : "opacity-100"
                      }`}
                    >
                      <span>
                        {Object.keys(dirtyFields).length === 0 ? (
                          <BsExclamationCircle />
                        ) : errorMessage.specialCharacter ? (
                          <Cross />
                        ) : (
                          <Check />
                        )}
                      </span>
                      <span className="xs:text-xs sm:text-13 lg:text-base">
                        Must contain a special character (!, %, @, #)
                      </span>
                    </div>
                  </div>

                  <PasswordInput
                    label="Confirm Password"
                    placeholder="Enter password"
                    register={register("cPassword", {
                      required: "Confirm Password is required",
                    })}
                    className={`${
                      errors.cPassword ? "border-Darkred" : "border-gray-400"
                    }`}
                    error={errors.cPassword?.message}
                  />
                </div>
                <div className="mt-5">
                  <Button
                    title="Create New Password"
                    variant="filled"
                    type="submit"
                    isLoading={isLoading}
                  />
                  <div className="relative flex items-center gap-1 justify-center mt-30">
                    <img src="/assets/images/arrow-left-short.svg" alt="" />
                    <span
                      className="text-BrandBlack text-base font-medium leading-6 cursor-pointer"
                      onClick={handleNavigation}
                    >
                      Back To Login
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
