import { AiFillWarning } from "react-icons/ai";

const Warning = () => {
  return (
    <div className="relative h-9 w-9 min-w-[36px] flex items-center justify-center bg-[#FF9E2C] text-white text-xl rounded-md">
      <AiFillWarning />
    </div>
  );
};

export default Warning;
