import React, { ChangeEventHandler } from "react";
import cx from "classnames";
import { UseFormRegisterReturn } from "react-hook-form";
import { PiWarningCircle } from "react-icons/pi";

interface InputProps {
  register?: UseFormRegisterReturn;
  type?: string;
  className?: string;
  labelClassName?: string;
  parentClassName?: string;
  placeholder?: string;
  label?: string;
  error?: any;
  required?: boolean;
  disable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  isAutoComplete?: string;
  noLabelrequired?: boolean;
  regExp?: RegExp | null;
  autoFocus?: boolean;
  onBlur?: ChangeEventHandler;
  onFocus?: ChangeEventHandler;
  name?:string;
}
const Input: React.FC<InputProps> = ({
  type = "text",
  labelClassName = "",
  parentClassName = "",
  placeholder,
  label = "",
  error = "",
  className = "",
  required = false,
  register,
  disable = false,
  onChange,
  value,
  isAutoComplete,
  noLabelrequired,
  regExp = null,
  autoFocus,
  onBlur,
  onFocus,
  name,
}) => {
  const classes = cx(
    className,
    "relative bg-white w-full py-1.5 px-3 border rounded-lg placeholder:text-gray-600 text-base font-normal leading-150% focus:outline-none",
    disable ? "bg-gray-200 cursor-not-allowed" : "border-solid border-gray-400",
    !error ? "focus:!border-gray-600" : "!border-Darkred pr-8"
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (regExp) {
      const inputValue = event.target.value.replace(regExp, "");
      event.target.value = inputValue;
    }
  };

  return (
    <div className={`relative ${parentClassName} `}>
      {label && (
        <label
          className={`text-contentcolor text-base leading-150% font-normal mb-1.5 block ${labelClassName} `}
        >
          {label}
          {required && <span className="text-red-Darkred font-medium">*</span>}
        </label>
      )}
      {noLabelrequired && (
        <span className="text-red-Darkred font-medium">*</span>
      )}
      <div className="relative w-full">
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          autoComplete={isAutoComplete}
          className={classes}
          disabled={disable}
          onChange={onChange}
          onInput={handleInputChange}
          autoFocus={autoFocus}
          onBlur={onBlur && onBlur}
          onFocus={onFocus}
          {...register}
        />
        {name && name === 'contractPrice' && (
          <span className="absolute right-8 z-[1]s top-2/4 -translate-y-2/4">
            USD
          </span>
        )}
        {error && (
          <span className="absolute right-3 z-[1] text-Darkred top-2/4 -translate-y-2/4">
            <PiWarningCircle />
          </span>
        )}
      </div>
      {error && (
        <p className="text-Darkred text-sm font-normal leading-150% mt-1">
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
