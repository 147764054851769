import React from "react";
import cx from "classnames";

const CheckBox = ({
  label = false,
  isSwitch = false,
  parentClassName,
  labelClassName,
  size = "md",
  border = "md",
  register,
  error,
  labelFirst = false,
}: {
  label?: any;
  isSwitch?: any;
  parentClassName?: any;
  labelClassName?: any;
  size?: "sm" | "md" | "lg";
  border?: "sm" | "md";
  register?: any;
  error?: any;
  labelFirst?: any;
}) => {
  const checkWrapClass = cx(
    `relative flex items-center border-2 border-gray-400 bg-white rounded transition-all duration-300 overflow-hidden h-4 w-4`
  );
  const checkClass = cx(
    `absolute flex items-center justify-center inset-0 peer-checked:bg-blacklight rounded scale-0 peer-checked:scale-100 transition-all text-white text-base`,
  );

  return (
    <div
      className={`checkWrap relative flex flex-col gap-2 ${parentClassName}`}
    >
      <label
        className={`flex items-center gap-2 ${isSwitch ? "isSwitch" : ""}`}
      >
        {labelFirst && label ? (
          <p className={`${labelClassName} text-BrandBlack`}>{label}</p>
        ) : (
          ""
        )}
        <span className=" relative flex items-center">
          <input
            type="checkbox"
            className={` peer rounded appearance-none absolute inset-0 opacity-0 ${size}`}
            name=""
            id=""
            {...register}
          />
          <span className={checkWrapClass}></span>
          <span className={checkClass}>
            <img src="/assets/images/check.png" alt="" />
          </span>
        </span>
        {!labelFirst && label ? (
          <p
            className={`${labelClassName} text-BrandBlack text-base leading-150% font-normal`}
          >
            {label}
          </p>
        ) : (
          ""
        )}
      </label>
      {error && (
        <div className={`flex w-full`}>
          <p className="text-Darkred text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};

export default CheckBox;
