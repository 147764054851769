import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Loader from "./Loader";
import { closeOnEscape } from "../../utils";

const Dialog = ({
  width,
  children,
  className,
  isOpen,
  closeIcon = true,
  onClose,
  title,
  minHight = "h-auto",
  isLoading,
  showDivisonLine = true,
  parentClass,
  TitleclassName,
}: {
  width?: string;
  children?: React.ReactNode;
  className?: string;
  parentClass?: string;
  minHight?: string;
  isOpen?: boolean;
  closeIcon?: boolean;
  onClose: () => void;
  title?: string;
  isLoading?: boolean;
  showDivisonLine?: boolean;
  TitleclassName?: string;
}) => {

  useEffect(() => {
    closeOnEscape(onClose);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 overflow-x-hidden overflow-y-auto z-999">
            <div
              className={`relative w-full flex justify-between min-h-screen items-center py-10 mx-auto ${
                width ? width : "max-w-90% md:max-w-675"
              }`}
            >
              <div
                className={`fixed inset-0 bg-black/10 backdrop-blur-sm ${parentClass}`}
              />
              <div
                className={`${
                  isLoading && minHight
                } rounded-14 relative flex flex-col w-full bg-white shadow-dialogshadow`}
              >
                {isLoading ? (
                  <Loader
                    size="h-10 w-10"
                    className="inset-[unset] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                ) : (
                  <>
                    {title || closeIcon ? (
                      <div className="relative  flex items-center px-5 sm:px-10 mr-5 sm:mr-10 pt-5">
                        {title && (
                          <h3 className={` text-BrandBlack text-22 font-medium leading-normal ${TitleclassName}`}>
                            {title}
                          </h3>
                        )}
                        {closeIcon && (
                          <button
                            className="absolute right-0 text-dark text-xl hover:text-primary hover:rotate-180 transition-all duration-500 p-2  rounded-md"
                            onClick={onClose}
                          >
                            <AiOutlineClose className="text-2xl text-BrandBlack" />
                          </button>
                        )}
                      </div>
                    ) : null}
                    {showDivisonLine && (
                      <hr className="mb-5 mt-4 w-full h-0.5 bg-eightgrey"></hr>
                    )}
                    <div className={`relative w-full p-5 pt-0 sm:p-10 sm:pt-0 ${className}`}>{children}</div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div className="fixed inset-0 overflow-x-hidden overflow-y-auto z-[99] ">
            <div
              className={`relative w-full flex justify-between min-h-screen items-center py-10 z-[1]  px-4 mx-auto ${
                width ? width : "max-w-3xl"
              }`}
            >
              <div
                className="fixed inset-0 bg-white/50 backdrop-blur-sm"
                onClick={onClose}
              />
              <div className="rounded-[20px] relative flex flex-col w-full bg-white p-8 shadow-[0px_8px_28px_0px_rgba(0,0,0,0.10)] ">
                {title || closeIcon ? (
                  <div className="relative  flex items-center mb-7 pr-6">
                    {title && (
                      <h3 className="font-semibold text-dark text-xl">
                        {title}
                      </h3>
                    )}
                    {closeIcon && (
                      <button
                        className="absolute right-0 text-dark text-xl hover:text-primary hover:rotate-180 transition-all duration-500"
                        onClick={onClose}
                      >
                        <AiOutlineClose />
                      </button>
                    )}
                  </div>
                ) : null}
                <div className={`relative ${className}`}>{children}</div>
              </div>
            </div>
          </div>
          <div>
            <SectionLoader size="h-10 w-10" />
          </div> */}
        </>
      ) : null}
    </>
  );
};

export default Dialog;
