import {
  Administrationicon,
  CompanyIcon,
  DashboardIcon,
  Jobsicon,
  MaxComponentsIcon,
  Reportsicon,
  Schedulesicon,
  Transporticon,
} from "icons";
import { CiViewTimeline } from "react-icons/ci";
import { PiHammerDuotone } from "react-icons/pi";
import { INavOptions } from "types/layout";

export const NavigationOptions: Array<INavOptions> = [
  {
    title: "Dashboard",
    value: "dashboard",
    link: "/dashboard",
    iconDetails: {
      icon: <DashboardIcon />,
      props: { className: "w-full h-full" },
    },
    // subNavigationOptions: [],
    moduleName: ["Dashboard"],
    isAllow: true,
  },
  {
    title: "Company",
    value: "company",
    link: "/company",
    iconDetails: {
      icon: <CompanyIcon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Company"],
    isAllow: true,
  },
  {
    title: "Job",
    value: "job",
    link: "/jobs",
    iconDetails: {
      icon: <Jobsicon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Job"],
    isAllow: true,
  },
  {
    title: "Schedule",
    value: "schedule",
    link: "/schedules",
    iconDetails: {
      icon: <Schedulesicon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Schedule"],
    isAllow: true,
  },
  {
    title: "Template",
    value: "templates",
    link: "/templates",
    iconDetails: {
      icon: <Reportsicon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Template"],
    isAllow: true,
  },
  {
    title: "Transport",
    value: "transport",
    link: "/transport",
    iconDetails: {
      icon: <Transporticon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [
      {
        title: "Delivery",
        value: "deliverys",
        link: "/transport/deliverys",
        isAllow: true,
        moduleName: ["Delivery"],
      },
      {
        title: "Pick-up",
        value: "pickups",
        link: "/transport/pickups",
        isAllow: true,
        moduleName: ["Pic-up"],
      },
      {
        title: "Order More Gear",
        value: "order-more-gear",
        link: "/transport/order-more-gear",
        isAllow: true,
        moduleName: ["Order More Gear"],
      },
      {
        title: "Hire",
        value: "hire",
        link: "/transport/hire",
        isAllow: true,
        moduleName: ["Hire"],
      },
    ],
    moduleName: ["Transport"],
    isAllow: true,
  },
  {
    title: "Builder",
    value: "builder",
    link: "/builder",
    iconDetails: {
      icon: <PiHammerDuotone />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Builder"],
    isAllow: true,
  },
  {
    title: "Administration",
    value: "administration",
    link: "/administration",
    iconDetails: {
      icon: <Administrationicon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    moduleName: ["Administration"],
    isAllow: true,
    subNavigationOptions: [
      {
        title: "User",
        value: "users",
        link: "/administration/users",
        isAllow: true,
        moduleName: ["User"],
      },
      {
        title: "Role",
        value: "roles",
        link: "/administration/roles",
        isAllow: true,
        moduleName: ["Role"],
      },

      {
        title: "Supplier",
        value: "suppliers",
        link: "/administration/suppliers",
        isAllow: true,
        moduleName: ["Supplier"],
      },
      {
        title: "Vehicle",
        value: "vehicles",
        link: "/administration/vehicles",
        isAllow: true,
        moduleName: ["Vehicle"],
      },
    ],
  },
  {
    title: "Max-Component",
    value: "max-component",
    link: "/max-components",
    iconDetails: {
      icon: <MaxComponentsIcon />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["Max-Component"],
    isAllow: true,
  },
  {
    title: "TimeSheet",
    value: "timesheets",
    link: "/timesheets",
    iconDetails: {
      icon: <CiViewTimeline />,
      props: { className: "w-full h-full" },
    },
    isSubNavigationOpen: false,
    // subNavigationOptions: [],
    moduleName: ["TimeSheet"],
    isAllow: true,
  },
];
