import { AiFillWarning } from 'react-icons/ai';

const Error = () => {
  return (
    <div className='relative h-9 w-9 min-w-[36px] flex items-center justify-center bg-[#DC2B2B] text-white text-xl rounded-md' >
      <AiFillWarning />
    </div>
  );
};

export default Error;