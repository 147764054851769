import Select from 'components/common/formInput/Select';
import Dialog from 'components/common/Dialog';
import Loader from 'components/common/Loader';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/common/button/Button';
import { getJobList } from 'services/dashboard';

interface CheckInModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    selectedUserId?: string;
    submitBtnTitle?: string;
    closeButton?: boolean;
    isLoading: boolean;
    onSubmit: (values: any) => void;
}

const CheckInModal = (props: CheckInModalProps) => {
    const { onClose, isOpen, title, onSubmit, submitBtnTitle } = props;
    const [innerLoader, setInnerLoader] = useState(true);
    const [siteAddressOption, setSiteAddressOption] = useState<{ label: string; value: string }[]>([]);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: async () => await fetchSelectedUserData(),
    });

    const fetchSelectedUserData = async () => {
        try {
            const listOfJobs = await getJobList()
            if (listOfJobs.status === 200) {
                const siteNameIds = listOfJobs.data.data.map((data: { location: string, _id: string }) => {
                    return {
                        label: data.location,
                        value: data._id,
                    };
                });
                setSiteAddressOption(siteNameIds)
                setInnerLoader(false);
            }
        } catch (error) {
            setInnerLoader(false);
        }
    }

    return (
        <div>
            <Dialog onClose={onClose} isOpen={isOpen} title={title}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`${innerLoader ? "h-406px rounded-14" : "h-full"}`}
                >
                    {innerLoader ? (
                        <Loader className={"rounded-14"} />
                    ) : (
                        <div className="flex gap-6 flex-col">
                            <Select
                                label="Site Address"
                                options={siteAddressOption}
                                placeholder={"Select Site Address"}
                                defaultValue={
                                    getValues("jobId")
                                        ? siteAddressOption.filter(
                                            (opt: { label: string; value: string }) =>
                                                opt.value === getValues("jobId")
                                        )
                                        : null
                                }
                                onChange={(e) => {
                                    clearErrors("jobId");
                                    if (!e) {
                                        setValue('jobId', null)
                                        // setError('jobId',{message:'Please Select Job Address'})
                                        return
                                    };
                                    setValue("jobId", e.value);
                                }}
                                register={register("jobId",{
                                    required:'Job Address is Required Field'
                                })}
                                parentClassName="w-full"
                                isSearchable={true}
                                border="sm"
                                isClearable
                                error={errors.jobId?.message as string}
                            />
                            <div className="flex items-center justify-end gap-2.5">
                                <Button
                                    type="button"
                                    title={"Close"}
                                    onClick={onClose}
                                    className={
                                        "!bg-BrandBlack/10 border !text-black !w-auto px-6"
                                    }
                                />

                                <Button
                                    type="submit"
                                    variant="filled"
                                    // isLoading={isLoading}
                                    className={"!w-auto px-6"}
                                    title={submitBtnTitle}
                                />
                            </div>
                        </div>
                    )}
                </form>
            </Dialog>
        </div>
    );
}

export default CheckInModal