import { INavOptions } from "types/layout";

export const NavigationOptions: Array<INavOptions> = [
  {
    title: 'Dashboard',
    value: 'dashboard',
    link: '/dashboard',
    count: 0,
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    subNavigationOptions: [],
    moduleName: ['dashboard'],
    isAllow: true,
  },
  {
    title: 'Company',
    value: 'company',
    link: '/company',
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [],
    moduleName: ['company'],
    isAllow: true,
  },
  {
    title: 'Jobs',
    value: 'jobs',
    count: 0,
    link: '/jobs',
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [],
    moduleName: ['jobs'],
    isAllow: true,
  },
  {
    title: 'Schedules',
    value: 'schedules',
    link: '/schedules',
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [],
    moduleName: ['schedules'],
    isAllow: true,
  },
  {
    title: 'Templates',
    value: 'templates',
    link: '/templates',
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [],
    moduleName: ['templates'],
    isAllow: true,
  },
  {
    title: 'Transport',
    value: 'transport',
    link: '/transport',
    iconDetails: {
      icon: 'transport',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    subNavigationOptions: [],
    moduleName: ['transport'],
    isAllow: true,
  },
  {
    title: 'Administration',
    value: 'administration',
    link: '#',
    iconDetails: {
      icon: '',
      props: { className: 'w-full h-full' },
    },
    isSubNavigationOpen: false,
    moduleName: ['users', 'roles'],
    isAllow: true,
    subNavigationOptions: [
      {
        title: 'Users',
        value: 'users',
        link: '/administration/users',
        status: 'Activated',
        moduleName: ['users'],
      },
      {
        title: 'Roles',
        value: 'roles',
        link: '/administration/roles',
        status: 'Completed',
        moduleName: ['roles'],
      },
    ],
  },
];

export const userRolesCategories: { [key: string]: string[] } = {
  Staff: ['Dashboard', 'Jobs', 'Schedules', 'Reports', 'Transport', 'Builders', 'Profile'],
  Admin: ['Dashboard', 'Company', 'Job', 'Schedule', 'Report', 'Transport', 'Builder', 'Administration', "Profile", 'User', "Role", "404", "Max-Component",'Template','Notification','TimeSheet'],
  companyAdmin: ['Dashboard', 'Job', 'Schedule', 'Report', 'Transport', 'Builder', 'Administration', "Profile", 'User', "Role", "404", "Max-Component",'Template','Notification','TimeSheet'], 
  Worker: ['Dashboard', 'Jobs', 'Schedules', 'Reports', 'Transport', 'Builders', "Profile", "Max-Components"],
  Company: ['Dashboard', 'Jobs', 'Schedules', 'Reports', 'Transport', 'Builders', 'Administration', "Profile", 'Users', "Roles","Max-Components",'Templates'],
};

export const UserRole: { [key: string]: string } = {
  Admin: 'admin',
  // companyAdmin:'companyAdmin'
  // Staff: 'staff',
  // Company: 'company',
  // Worker: 'worker'
}

const defaultModules = {
  Dashboard: ['create', 'edit', 'list', 'delete'],
  Profile: ['create', 'edit', 'list', 'delete'],
  Notification: ['create', 'edit', 'list', 'delete'],
  TimeSheet:['create','edit','list','delete'],
  404:['create', 'edit', 'list', 'delete']
};


export const getUserRole = (role: string, permissions: string[]) => {
  // Normalize to lowercase for case-insensitive comparison
  const normalizedRole = role?.toLowerCase();
  // Find the matching role in the enum
  const matchedRole = Object.keys(UserRole).find(
    (enumRole) => UserRole[enumRole].toLowerCase() === normalizedRole
  );

  if (matchedRole) return [matchedRole, "existing"]
  const resultArray: any = {};

  permissions.forEach((item: any) => {
    const [moduleName, permission] = item.name.split('_');
    const modifiedModuleName = `${moduleName.charAt(0).toUpperCase()}${moduleName.slice(1)}`; // Capitalize first letter and append 's' to the module name
    if (!resultArray[modifiedModuleName]) {
      resultArray[modifiedModuleName] = [permission];
    } else {
      resultArray[modifiedModuleName].push(permission);
    }
  });

  Object.entries(defaultModules).forEach(([moduleName, permissions]) => {
    if (!resultArray[moduleName]) {
      resultArray[moduleName] = permissions;
    } else {
      resultArray[moduleName].push(...permissions);
    }
  });

  const finalArray = Object.entries(resultArray).map(([moduleName, permissions]) => ({ [moduleName]: permissions }));

  return [finalArray, "custom"]
};