import { validateField } from "helper/common/constant";
import * as Yup from "yup";

const { email, password, forgetEml, stringPrefixJoiValidation } = validateField;

export const loginSchema: Yup.ObjectSchema<any, Yup.AnyObject, any, ""> =
  Yup.object({
    email: email.required(),
    password: Yup.string(),
  });

export const forgetEmail: Yup.ObjectSchema<any, Yup.AnyObject, any, ""> =
  Yup.object({
    email: forgetEml.required(),
  });

export const resetPasswordSchema: Yup.ObjectSchema<
  any,
  Yup.AnyObject,
  any,
  ""
> = Yup.object({
  rePassword: password,
  cPassword: Yup.string()
    .required("Confirm Password is Required")
    .trim()
    .min(1, "Confirm Password is Required")
    .oneOf([Yup.ref("rePassword")], "Passwords do not match. Try Again"),
});

export const changePasswordConstraint: Yup.ObjectSchema<
  any,
  Yup.AnyObject,
  any,
  ""
> = Yup.object({
  cPassword: password,
  nPassword: Yup.string()
    .required("New Password is Required")
    .trim()
    .min(8, "Minimum 8 character is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]{8,25}$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  rPassword: Yup.string()
    .required("re enter new Password is Required")
    .trim()
    .min(8, "re enter new Password is Required")
    .oneOf([Yup.ref("nPassword")], "Passwords does not match"),
});

export const nameConstraint: Yup.ObjectSchema<any, Yup.AnyObject, any, ""> =
  Yup.object({
    name: stringPrefixJoiValidation
      .required("Name is Required")
      .min(2, "Name should be more than 2 character."),
    phone: validateField.stringPrefixJoiValidation
      .required("Phone is Required")
      // .min(10, "Please Enter a 10-digit Number")
      // .max(10, "Please Enter a 10-digit Number")
      .matches(/^\d+$/, { message: "Please Enter Digits Only!!" }),
    // .test(
    //   "validate-phone",
    //   "Invalid Phone Number",
    //   function (this: Yup.TestContext, value: string) {
    //     if (this.parent && this.parent.countryCode) {
    //       return isValidPhoneNumber(`${this.parent.countryCode}${value}`);
    //     }
    //     return false; // Handle the case where countryCode is not defined
    //   }
    // ),
    countryCode: validateField.stringPrefixJoiValidation.required(
      "Country Code is Required"
    ),
  });
