import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IForgetPassword } from "../../types/auth/auth";
import { useDispatch } from "react-redux";
import Button from "components/common/button/Button";
import Input from "components/common/formInput/Input";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "services/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgetEmail } from "validation/auth/authValidation";
import { toastShow } from "redux/ducks/toast";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const intialValue = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IForgetPassword>({
    defaultValues: intialValue,
    resolver: yupResolver(forgetEmail),
  });

  const handleNavigation = () => {
    navigate("/login");
  };

  const onSubmit: SubmitHandler<IForgetPassword> = async (data) => {
    setLoading(true);
    try {
      const responseData = await forgetPassword(data);
      if (responseData.status === 200) {
        //logic for success
        navigate("/sentEmail", {
          state: {
            email: data.email,
          },
        });
      }
      if (responseData.data.statusCode === 400) {
        setError("email", { message: responseData.data.message });
      }
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
      dispatch(toastShow({ message: "Something went wrong", type: "error" }));
    }
  };

  return (
    <div className="relative bg-bodybg w-full h-screen">
      <div className="relative top-2/4 -translate-y-2/4 lg:top-0 lg:translate-y-0 lg:py-10 2xl:top-2/4 2xl:-translate-y-2/4 2xl:py-0">
        <div className="relative text-center">
          <img
            src="/assets/images/logo.svg"
            className="mx-auto mb-61 xs:px-5 md:px-0 xs:max-w-75% sm:max-w-full"
            alt="logo"
          />
        </div>
        <div className="flex justify-center rounded-xl">
          <div className="relative md:max-w-300 lg:max-w-375 w-full rounded-l-lg border border-solid border-loginBorder border-r-0 hidden md:block">
            <img
              src="/assets/images/Login-Banner.png"
              className="relative h-full w-full object-cover object-center rounded-l-lg"
              alt="logo"
            />
          </div>
          <div className="bg-white flex items-center xs:px-5 xs:py-5 md:py-0 md:px-10 lg:px-84 xs:max-w-full xs:mx-5 md:mx-0 md:max-w-400 lg:max-w-570 w-full rounded-r-lg border border-solid border-loginBorder md:border-l-0 xs:border-l">
            <div className="relative w-full">
              <div className="relative">
                <h2 className="text-BrandBlack text-3xl font-medium leading-normal xs:mb-5 md:mb-6 lg:mb-30">
                  Forgot Password
                </h2>

                <p className="text-BrandBlack text-base md:text-lg font-normal leading-6 xs:mb-6 md:mb-8 lg:mb-60">
                  Enter an email so that we can send you password reset link
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className=" flex gap-6 flex-col">
                  <Input
                    label="Email"
                    placeholder="Enter email"
                    type="text"
                    className={`${errors.email ? "border-Darkred" : "border-gray-400"
                      }`}
                    register={register("email")}
                    error={errors.email?.message}
                  />
                </div>
                <div className="xs:mt-60 md:mt-16 lg:mt-105">
                  <Button
                    title="Send Email"
                    variant="filled"
                    type="submit"
                    isLoading={isLoading}
                  />
                  <div className="relative flex items-center gap-1 justify-center mt-30">
                    <img src="/assets/images/arrow-left-short.svg" alt="" />
                    <span
                      className="text-BrandBlack text-base font-medium leading-6 cursor-pointer"
                      onClick={handleNavigation}
                    >
                      Back To Login
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
