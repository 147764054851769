import { FaAngleRight } from "react-icons/fa6";
import { INavOptions } from "types/layout";
import { useEffect, useState } from "react";
import { TABLET_VIEW } from "helper/common/constant";

interface IMenu {
  menuData: INavOptions;
  isActive: boolean;
  collapsed: boolean;
  onNavigate: (path: string) => void;
  isSubMenuVisible: string | null;
  setSubMenuVisible: (menuId: string | null) => void;
  activePath: string;
  onToggleSidebar: () => void
}

const SideBarMenu = ({
  menuData,
  isActive,
  onNavigate,
  isSubMenuVisible,
  setSubMenuVisible,
  activePath,
  collapsed,
  onToggleSidebar,
}: IMenu) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOnClick, setIsOnClick] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= TABLET_VIEW && isOnClick === true && setIsOnClick(false);
    }
    // // On load to collapse sidebar 
    // handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // collapsed === false && setSubMenuVisible(null);
    isOnClick === true && setIsOnClick(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed])

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (window.innerWidth < TABLET_VIEW) {
      setIsOnClick(false);
    }
  };

  const handleNavigation: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (window.innerWidth < TABLET_VIEW) {
      setIsOnClick(true);
      if (collapsed) return
    }
    if (!menuData?.subNavigationOptions) {
      onNavigate(menuData.link || "");
    }
    if (isSubMenuVisible === menuData.link) {
      setSubMenuVisible(null);
    } else {
      setSubMenuVisible(menuData.link);
    }
    !menuData?.subNavigationOptions && window.innerWidth <= TABLET_VIEW && onToggleSidebar()
  };
  return (
    <>
      <div
        className={`flex items-center justify-between gap-2.5 text-BrandBlack text-base py-2.5 px-2 rounded-lg font-medium leading-normal cursor-pointer transition duration-100 overflow-hidden ${isActive ? "bg-menubg" : ""
          }`}
        onClick={(e) => handleNavigation(e)}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <div className="relative flex items-center gap-2.5 whitespace-nowrap">
          <span className="flex items-center text-3xl">
            {menuData.iconDetails?.icon}
          </span>
          <span>{menuData.title} </span>

        </div>
        {menuData?.subNavigationOptions && (
          <FaAngleRight
            className={`transition-all ease-in-out duration-300 ${isSubMenuVisible !== menuData.link ? "rotate-0" : "rotate-90"
              }`}
          />
        )}
      </div >
      {collapsed && (isHovered || isOnClick) && menuData?.subNavigationOptions &&
        <div className="pl-20 md:fixed xl:absolute -mt-14 "
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <ul className="w-auto p-2 shadow-dialogshadow relative rounded-lg bg-white z-50 before:absolute before:border-8 before:border-solid before:border-transparent before:border-r-white  before:-left-5 before:w-5 before:h-5 before:top-5">
            {menuData?.subNavigationOptions?.map((item, index: number) => {
              return (
                <li key={index} className="">
                  <div
                    className={`px-5 text-BrandBlack text-base py-2.5 font-medium leading-normal cursor-pointer block ${isActive && activePath === item.link ? "bg-menubg" : ""
                      }`}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsHovered(false);
                      onNavigate(item.link);
                      setSubMenuVisible(menuData.link);
                      isOnClick === true && setIsOnClick(false);
                    }}
                  >
                    {item.title}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      }
      {!collapsed && <ul className="w-full">
        {!collapsed && isSubMenuVisible === menuData.link &&
          menuData?.subNavigationOptions?.map((item, index: number) => {
            return (
              <li key={index} className="">
                <div
                  className={`pl-12 text-BrandBlack text-base py-2.5 font-medium leading-normal cursor-pointer block rounded-lg ${isActive && activePath === item.link ? "bg-menubg" : ""
                    }`}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.innerWidth <= TABLET_VIEW && onToggleSidebar()
                    onNavigate(item.link);
                  }}
                >
                  {item.title}
                </div>
              </li>
            );
          })}
      </ul>}
    </>
  );
};

export default SideBarMenu;
