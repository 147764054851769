import { axiosDelete, axiosGet, axiosPost } from "axios/config";

export const saveActivityLog = (data: object) => {
  return axiosPost(`/job/timesheet/create`, data);
};

export const getLogData = (jobId: object) => {
  return axiosGet(`/job/timesheet/logs`, jobId);
};

export const getTimeSheetData = (param: object) => {
  return axiosGet(`/job/timesheet`, param);
};

export const exportToCsv = (param: object) => {
  return axiosGet(`/job/timesheet-export`, param);
};

export const getListOfUsers = (param: object) => {
  return axiosGet(`/job/timesheet/users`, param);
};
export const saveManualEntry = (data: object) => {
  return axiosPost(`/job/timesheet/menually`, data);
};

export const editTimesheetDetails = (data: object) => {
  return axiosPost(`/job/timesheet`, data);
};

export const getJobList = () => {
  return axiosGet(`/timesheet/all-sites`);
};

export const getAllJobList = (param: object) => {
  return axiosGet(`/timesheet/all-user-jobs`, param);
};

export const getAllTimesheetDetails = (id: string, param: object) => {
  return axiosGet(`/timesheet/all-user-jobs/${id}`, param);
};

export const AllTImeSheetExportToCsv = (param: object) => {
  return axiosGet(`/timesheet/all-data-timesheet-export`, param);
};

export const getListOfAllUsers = () => {
  return axiosGet(`/job/timesheet/all-users`);
};

export const softDeleteLog = (userId: string, jobId: string) => {
  return axiosDelete(`/job/timesheet/delete/${userId}/${jobId}`);
};

export const deleteTimesheetLog = (data: {
  userId: string;
  jobId: string;
  checkInId: string;
}) => {
  return axiosDelete(`/job/timesheet/delete`, data);
};
