import { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/custom-toast-styles.css";

import Success from "./Succes";
import Error from "./Error";
import Info from "./Info";
import Warning from "./Warning";
import { toastShow, IToastType } from "redux/ducks/toast";

const ToastNotification = () => {
  const toastMessage = useSelector(
    (state: { toast: IToastType }) => state.toast
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (toastMessage.message) {
      if (toastMessage.type === "error") {
        toast.error(
          <div className="flex items-center gap-3">
            <Error />
            <span>{toastMessage.message}</span>
          </div>
        );
      } else if (toastMessage.type === "success") {
        toast.success(
          <div className="flex items-center gap-3">
            <Success />
            <span>{toastMessage.message}</span>
          </div>
        );
      } else if (toastMessage.type === "warning") {
        toast.warning(
          <div className="flex items-center gap-3">
            <Warning />
            <span>{toastMessage.message}</span>
          </div>
        );
      } else {
        toast(
          <div className="flex items-center gap-3">
            <Info />
            <span>{toastMessage.message}</span>
          </div>
        );
      }
      setTimeout(() => {
        dispatch(toastShow({ message: null, type: null }));
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastMessage?.message]);

  return (
    <ToastContainer
      className="!z-[9999999]"
      position="top-center"
      autoClose={2500}
      hideProgressBar={true}
      newestOnTop
      closeOnClick
      closeButton={(props) => {
        return (
          <CgClose
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              color: "black",
              height: "16px",
              cursor: "pointer",
            }}
          />
        );
      }}
      toastClassName={({ type }: any) => {
        return `relative flex border p-2.5 bg-white border w-full justify-between rounded-lg z-[9999999] ${
          type === "error"
            ? "border-[#DC2B2B]/50"
            : type === "success"
            ? "border-[#67BD6D]/50"
            : type === "warning"
            ? "border-[#FF9E2C]/50"
            : "border-[#1877F2]/50"
        }`;
      }}
      bodyClassName={() => "flex text-sm text-black w-full"}
    />
  );
};

export default ToastNotification;
