import React from "react";
import cx from "classnames";

const Button = ({
  className = "",
  title,
  icon = false,
  iconFirst = false,
  onClick,
  titleClassName,
  type,
  disabled,
  isLoading = false,
}: {
  className?: any;
  title?: any;
  icon?: any;
  iconFirst?: any;
  onClick?: any;
  active?: any;
  isSquare?: boolean;
  titleClassName?: any;
  type?: "submit" | "reset" | "button";
  variant?: "filled" | "outline";
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  const classes = cx(
    className,
    `group font-medium bg-BrandBlack rounded-lg py-2.5 text-white text-base leading-normal w-full whitespace-nowrap `,
    disabled ? "opacity-50 cursor-not-allowed " : "",
  );

  return (
    <button
      onClick={onClick}
      type={type}
      className={classes}
      disabled={disabled}
    >
      <div className="flex justify-center gap-5">
        {isLoading ? (
          <span className="relative h-5 w-5 border-[3px] border-white border-b-gray-400 rounded-full block animate-spin" />
        ) : iconFirst && icon ? (
          <span className="">{icon}</span>
        ) : null}
        {title && <span className={titleClassName}>{title}</span>}
      </div>
      {!iconFirst && icon ? <span className="">{icon}</span> : null}
    </button>
  );
};

export default Button;
